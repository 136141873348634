.header-middle-container {
    display: flex;
    align-items: center;
}

.generate-video-icon-container {
    width: 38px;
    height: 38px;
    border: 1px solid #6E737D;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 24px;
    cursor: pointer;
}
.generate-video-icon-container img {
    width: 17px;
}