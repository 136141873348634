.swiper-slide-custom {
  text-align: left !important;
  height: 463px !important;
}

.left-side {
  padding: 8px 8px 0px 8px;
  width: 100%;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  overflow: hidden;
  position: relative;
  
}
.left-side::before{
  content: "";
  position: absolute;
  top:0px;
  right:0px;
  bottom:0px;
  left:0px;
  z-index: 0;  
  border: solid 1px var(--video-box-border);
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
.left-side .swiper, .left-side .MuiGrid-container{
  z-index: 2;
}
.left-side .MuiGrid-container{
  position: relative;
}

.no-image {
  position: relative;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  width: 100%;
  height: 463px;
  display: flex;
  color: #ffffff;
  align-items: center;
  justify-content: center;
}

.zoom-out-icon {
  margin-right: 4px;
  width: 22px;
  height: 21px;
  transform: scale(0.8)
}

.zoom-in-icon {
  margin-left: 4px;
  width: 22px;
  height: 21px;
  transform: scale(0.8);
}

.image-page {
  margin-top: 8px !important;
}

.image-page .query-text-section {
  display: flex;
  margin-bottom: 18px;
  align-items: center;
  color: rgba(255, 255, 255, 0.7);
  text-transform: capitalize;
  font-size: 22px;
}

.image-page .image-box-header {
  display: flex;
  margin-bottom: 12px;
  align-items: center;
  color: rgba(255, 255, 255, 0.7);
  justify-content: space-between;
}

.image-page .image-box-header .left-section .movie-meta {
  display: flex;
  align-items: center;
}

.image-page .image-box-header .left-section .movie-meta p {
  text-transform: capitalize;
  font-size: 18px;
}

.image-page .image-box-header .left-section .movie-meta .rating-box {
  display: flex;
  align-items: center;
  margin-left: 6px;
  padding: 3px 5px 3px 3px;
  border: 1px solid #fff;
  border-radius: 5px;
  height: 26px;
}

.image-page .image-box-header .left-section .movie-meta .rating-box img {
  width: 38px;
  margin-right: 4px;
}

.image-page .image-box-header .left-section .movie-meta .rating-box .rating-box-content {
  display: flex;
  align-items: flex-end;
}

.image-page .image-box-header .left-section .movie-meta .rating-box .rating-box-content .rating-value {
  font-size: 17px;
  line-height: 17px;
  color: white;
  font-weight: 400;
}

.image-page .image-box-header .left-section .movie-meta .rating-box .rating-box-content .rating-max-value {
  font-size: 12px;
  line-height: 12px;
  color: white;
  font-weight: 400;
}

.image-page .image-box-header .left-section .result-section {
  text-align: left;
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0px;
  color: #989898;
  opacity: 1;
  margin-top: 7px;
}

.image-description-box {
  border: solid 1px var(--video-box-border);
  border-radius: 8px;
  padding: 14px;
}

.image-description-box h3 {
  font-size: 22px;
  line-height: 35px;
  color: var(--brand-white);
  font-weight: 400;
  margin: 0 0 5px;
}

.image-description-box h4 {
  font-size: 14px;
  line-height: 17px;
  color: var(--brand-light-gray);
  font-weight: 400;
  margin: 0 0 16px;
}

.image-description-box .add-to-playlist {
}

.image-description-box .add-to-playlist .custom-file-upload {
  padding: 8px;
  cursor: pointer;
  color: var(--brand-white);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background: var(--brand-black-light);
  border: dashed 1px var(--brand-light-gray);
  border-radius: 4px;
}

.image-description-box .add-to-playlist .custom-file-upload span {
  position: relative;
  padding-left: 25px;
}

.image-description-box .add-to-playlist .custom-file-upload span:before {
  content: "";
  background: url(../../assets/icon-playlist-white.svg) 0 0 no-repeat;
  width: 15px;
  height: 15px;
  position: absolute;
  top: 0;
  left: 0;
}

.image-description-box .add-to-playlist .custom-file-upload span strong {
  font-size: 14px;
  line-height: 17px;
  font-weight: 300;
  color: var(--brand-white);
  display: block;
  margin-bottom: 4px;
}

.image-description-box .add-to-playlist .custom-file-upload span em {
  font-size: 10px;
  line-height: 12px;
  font-weight: 300;
  color: var(--brand-light-gray);
  display: block;
  font-style: normal;
}

.image-description-box .image-desc-list {
  padding: 0;
  margin: 0;
  counter-reset: item;
  max-height: 644px;
  overflow-y: auto;
  overflow-x: hidden;
  margin-left: -14px;
  margin-right: -14px;
}

.image-description-box .image-desc-list::-webkit-scrollbar {
  display: none;
}

.image-description-box .image-desc-list::-webkit-scrollbar {
  width: 5px;
  height: 8px;
  background-color: #aaa;
  /* or add it to the track */
}

.image-description-box .image-desc-list::-webkit-scrollbar-thumb {
  background: #000;
}

.image-description-box .image-desc-list li {
  color: white;
  list-style-type: none;
  position: relative;
  counter-increment: item;
  cursor: pointer;
  padding-left: 27px;
  padding-right: 14px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.image-description-box .image-desc-list li.selected,
.image-description-box .image-desc-list li:hover {
  background: var(--brand-black-light);
}

.image-description-box .image-desc-list li:before {
  content: counter(item);
  position: absolute;
  top: 36px;
  left: 6px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  color: var(--brand-white);
  font-size: 14px;
  line-height: 17px;
  min-width: 16px;
  text-align: right;
}

.image-description-box .image-desc-list li .small-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-column-gap: 8px;
  column-gap: 8px;
  margin-left: 0px;
}


.image-description-box .image-desc-list li .image-thumbnail img {
  max-width: 100%;
  border-radius: 4px;
  height: 64px;
  -o-object-fit: cover;
  object-fit: cover;
  display: block;
  width: 100px;
}
.image-description-box .image-desc-list li .image-thumbnail{
    max-width: 100px;
    flex: 1 0 auto;
    position: relative;
}

.image-description-box .image-desc-list li .image-desc {
  width: 100%;
}

.image-description-box .image-desc-list li .image-desc h3 {
  font-size: 13px;
  line-height: 14px;
  letter-spacing: 0.23px;
  margin-bottom: 6px;
  color: rgba(255, 255, 255, 0.7);
  padding-right: 24px;
  text-transform: capitalize;
}

.image-description-box .image-desc-list li .image-desc h4 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.14px;
  color: var(--brand-light-gray);
  margin-bottom: 0;
}
.image-description-box .image-desc-list li.timeline-right-list {
  margin: 6px 0px;
}

.image-description-box .image-desc-list li .image-desc h4 .image-desc-inner-content {
  /* display: flex; */
  align-items: center;
}

.image-description-box .image-desc-list li .icon-full-desc {
  position: absolute;
  top: 15px;
  right: 24px;
  color: var(--brand-white);
}

.image-description-box .image-desc-list li .icon-full-desc svg {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transition: all 0.1s ease-in;
  transition: all 0.1s ease-in;
}

.image-description-box .image-desc-list li .icon-full-desc.active svg {
  -webkit-transform: translate(-50%, -50%) rotate(-90deg);
  transform: translate(-50%, -50%) rotate(-90deg);
}

.image-description-box .image-desc-list li .full-desc {
  font-size: 12px;
  line-height: 16px;
  color: rgba(255, 255, 255, 0.7);
  font-weight: 300;
  display: none;
  margin-bottom: 0px;
  margin-top: 10px;
  margin-left: 0px;
}

.image-desc .confidence-level {
    font-size: 9px;
    line-height: 1;
    padding: 0px 28px 0px 20px;
    min-width: 42px;
    text-transform: capitalize;
}

.image-desc .btn-fav {
  padding:0px;
  margin-left: 0px;
}

.image-desc .btn-fav-playlist {
  padding:0px;
  margin-left: 6px;
}

.image-desc .btn-bug {
  padding:0px;
  margin-left: 13px;
}


.image-buttons-group{
  display: flex;
  justify-content: space-between;
  width:100%;
  padding-left:16px;
  padding-right:16px;
  padding-top:17px;
  padding-bottom:15px;
}
.image-buttons-group .play-pause-button {
  display: flex;
  column-gap: 18px;
  align-items: center;
}
.image-buttons-group .zoom-icon-group {
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 11px;
}
#mini-desc span {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;  
  overflow: hidden;
  line-height: 1.2;
}
.image-description-box .image-desc-list li .icon-card-close{
  position: absolute;
  right:30px;
  cursor: pointer;
  background:url(../../assets/iconCloseCard.svg) 0 0 no-repeat;
  width:20px;
  height: 20px;
}
.image-description-box .image-desc-list li.selected .icon-card-close,
.image-description-box .image-desc-list li:hover .icon-card-close{
  background-image:url(../../assets/iconCloseCardHover.svg);
}

.still-actions .MuiTooltip-tooltipArrow{
  padding:1px 5px !important;
  background:rgba(53, 51, 57, 1);
}
.still-actions .MuiTooltip-arrow::before{
  background:rgba(53, 51, 57, 1);
}