//@import url("https://use.typekit.net/gku5fpt.css");
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');
:root{
  --brand-black:#0C0C0C;
  --brand-black-88:rgba(15,14,17,.88);
  --brand-black-light:#1e1f20;
  --brand-white:#ffffff;
  --brand-white-60:rgba(255,255,255,.6);
  --brand-light-gray:#989898;
  --brand-light-gray-20:rgba(110,115,125, 0.20);
  --brand-light-gray-33:rgba(120,120,120, 0.33);
  --brand-light-gray-1-33:rgba(98,98,98, 0.33);
  --brand-dark-blue:#2674e3;
  --brand-gray-1:#6e737d;
  --brand-gray-2:#D2D2D2;
  --brand-gray-04:rgba(152,152,152,.2);
  --banner-overlay-height:350px;
  --banner-overlay-height-homepage:calc(var(--banner-overlay-height) * 0.50);
  --bg-black-26: rgba(0,0,0,.26);
  --video-box-border: rgba(91, 91, 91, 0.5);
  --theme2-green:#A2E985;
  --theme2-yellow:#FEC868;
  --theme2-cherry:#EC6B62;

}

body *{
    font-family: "Roboto", sans-serif !important;
}
h1, h2, h3, h4, h5, h6{
    font-family: "Roboto", sans-serif !important;
}


body{
  background:#0F0E11 !important;
}

.App {
  text-align: center;
}

#root {
  background-color: #000;
  min-height: 100vh;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
::-webkit-scrollbar-track {
  background: #000; 
}
::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.3); 
  border-radius: 10px;
  &:hover {
    background: rgba(255, 255, 255, 0.6);
  }
}

.header {
  left: 50% !important;
  transform: translateX(-50%);
  .main-header {
    height: auto !important;
    padding-top: 20px;
    padding-bottom: 20px;
    .MuiToolbar-gutters {
      padding-left: 0px;
      padding-right: 0px;
      min-height: inherit;
      .MuiIconButton-root {
        margin-left: 0px;
        top: 0px;
        left: 0;
        width: 24px;
        height:16px;
        border-radius: 0;
        padding: 0;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        .MuiTouchRipple-root{
            height: 2px;
            width:100%;
            background: white;
            position: static;
            &:before, &:after{
                display: none;
            }
        }
        &:before {
          content: "";
          position: absolute;
          top: 0px;
          left: 0px;
          width: 100%;
          height: 2px;
          background: white;
        }
        &:after {
          content: "";
          position: absolute;
          bottom: 0px;
          left: 0px;
          width: 100%;
          height: 2px;
          background: white;
        }
      }
    }
    .MuiFormControl-root {
      margin: 0px;
      .MuiOutlinedInput-root {
        padding-right: 0px !important;
        .MuiOutlinedInput-input{
          font-size:16px;
          font-weight: 300;
          &::placeholder{
            color:white;
            opacity: 0.40;
          }
        }
      }
    }
    .MuiChip-outlined {
      margin-right: 0px;
    }
  }
}

.homepage {
  padding-top: 0px;
  margin-top: calc(var(--banner-overlay-height-homepage) * -1); 
  position: relative;
  z-index: 1;
  @media(max-width:899.98px){
    margin-top: 0; 
  }
  .heading-bar {
    margin: 0 0 30px;
  }
  .h3 {
    font-size: 24px;
    line-height: 32px;
    color: white;
    margin: 0 0 0px;
    font-weight: 500;
  }
  .view-all {
    font-size: 16px;
    line-height: 19px;
    color: var(--brand-gray-2);
    margin: 0 0 0px;
  }
  .carousel-container{
    margin-left: -22px;
    margin-right: -22px;
    width:calc(100% + 44px);
    overflow: hidden;
    @media (max-width: 1599.98px) {
      margin-left: -12px;
      margin-right: -12px;
      width:calc(100% + 24px);
    }
  }
  .react-multi-carousel-list {
    overflow: visible;
    position: static;
    ul {
      li {
        padding-left: 22px;
        padding-right: 22px; 
        @media (max-width: 1599.98px) {
          padding-left: 12px;
          padding-right: 12px; 
        }
      }
    }
    .slide-item{
        //@extend %slide-item;
    }
  }
  .slider-list {
    position: relative;
    @media(min-width:0px){
      margin-bottom: 20px;
    }
    @media(min-width:600px){
      margin-bottom: 20px;
    }
    @media(min-width:900px){
      margin-bottom: 40px;
    }
    @media(min-width:1200px){
      margin-bottom: 60px;
    }
  }
  .react-multiple-carousel__arrow {
    &.react-multiple-carousel__arrow--left {
      left: -40px;
      background:transparent;
      min-width:11px;
      top:calc(50% - 24px);
      transform: translateY(-50%);
      &:before{
        content:"";
        background:url(assets/carousel-arrow-icon.svg) 0 0 no-repeat;
        width:11px;
        height:20px;
      }
    }
    &.react-multiple-carousel__arrow--right {
      right: -40px;
      background: transparent;
      min-width:11px;
      top:calc(50% - 24px);
      transform: translateY(-50%);
      &:before{
        content:"";
        background:url(assets/carousel-arrow-icon.svg) 0 0 no-repeat;
        width:11px;
        height:20px;
        transform: rotate(-180deg);
      }
    }
  }
}
.search-page-listing{
    @extend %slide-item;
    overflow: hidden;
}

%slide-item{
        &:hover{
            .backdrop-up{
                bottom:0;
            }
        }
        .backdrop-down{
            backdrop-filter: blur(17px);
            -webkit-backdrop-filter: blur(17px);
            border-radius: 16px;
            padding:16px 0px 0px;
        }
        .backdrop-up{
            backdrop-filter: blur(17px);
            -webkit-backdrop-filter: blur(17px);
            border-top-left-radius: 16px;
            border-top-right-radius: 16px;
            border-bottom-left-radius: 0px;
            border-bottom-right-radius: 0px;
            padding:22px 15px 20px;
            width: 100%;
        }
        .h3{
            font-size:16px;
            line-height: 19px;
            color:white;
            margin:0 0 11px;
            font-weight: 500;
        }
        .h4{
            font-size:12px;
            line-height: 13px;
            color:white;
            font-weight: 400;
            margin:0;
        }
        .p{
            font-size:13px;
            line-height: 16px;
            color:white;
            font-weight:300;
            margin:0;
        }
        .mb-16{
            margin-bottom: 16px;
        }
        .mb-12{
            margin-bottom: 12px;
        }
        .mb-8{
            margin-bottom: 8px;
        }
        .mb-0{
            margin-bottom: 0px;
        }
        .title-group{
            display: flex;
            column-gap: 10px;
            span{
                display: block;
                & + span{
                    position: relative;
                    &:before{
                        content:"";
                        position: absolute;
                        top:50%;
                        left:-5px;
                        width:1px;
                        height:10px;
                        background: #ffffff;
                        transform: translateY(-50%);
                    }
                }
            }
        }
        .buttons-group{
            display: flex;
            align-items: center;
            column-gap: 7px;
            button{
                background:#fff;
            }
            .btn-fav{
                svg{
                    fill:#E12B38;
                }
            }
            .btn-bug{
                svg{
                    fill:#000000;
                }
            }
            .btn-play{
                svg{
                    fill:#000000;
                }
            }
        }
        .badges{
            margin-bottom: 16px;
            button{
                border-radius: 50px;
                border: 1px solid #FFFFFF;
                color: #FFFFFF;
                text-transform: capitalize;
                margin-right: 18px;
                &:hover{
                    border: 1px solid #FFFFFF
                }
            }
        }
        .card-top-row{
            padding:16px 16px 0 16px;
        }
    
}

.container {
  width: 100%;
  padding-right: 24px;
  padding-left: 24px;
  margin-right: auto;
  margin-left: auto;
  box-sizing: border-box;
  //border-left:solid 1px red;
 // border-right:solid 1px red;
  @media (min-width: 576px) {
    max-width: 540px;
    padding-right: 12px;
    padding-left: 12px;
  }
  @media (min-width: 600px) {
    max-width: 560px;
    padding-right: 12px;
    padding-left: 12px;
  }
  @media (min-width: 900px) {
    max-width: 860px;
    padding-right: 12px;
    padding-left: 12px;
  }
  @media (min-width: 1200px) {
    max-width: 1140px;
  }
  @media (min-width: 1300px) {
    max-width: 1240px;
  }
  @media (min-width: 1400px) {
    max-width: 1320px;
  }
}


.footer{
    background:#0A0710;
    overflow: hidden;
    .footer-top{
        position: relative;
        @media(min-width:0px){
          padding-top: 12px;
          padding-bottom: 12px;
        }
        @media(min-width:600px){
          padding-top: 12px;
          padding-bottom: 12px;
        }
        @media(min-width:900px){
          padding-top: 12px;
          padding-bottom: 12px;
        }
        @media(min-width:1200px){
          padding-top: 18px;
          padding-bottom: 18px;
        }
        &:after{
            content: "";
            position: absolute;
            bottom:0px;
            left:50%;
            transform: translateX(-50%);
            width:1000%;
            height: 1px;
            background:rgba(152,152,152, 0.39);
        }
    }
    .footer-bottom{
        padding-top:20px;
        padding-bottom: 20px;
    }
}
.filter-bar{
    //need to remove this class attributes
    display: none !important;

}
.search-tab{
    background: #0A0710;
    @media(max-width:899.98px){
      padding-bottom: 12px;
    }
    button{
        font-size:16px;
        line-height: 19px;
        padding-top:27px;
        padding-bottom: 27px;
        padding-left:0px;
        padding-right:0px;
        color: rgba(110, 115, 125, .7);
        text-transform: none;
        @media(max-width:899.98px){
          padding-top: 12px;
          padding-bottom: 12px;
        }
        
        & + button{
            margin-left:32px;
            @media(max-width:899.98px){
              margin-left:16px;
            }
        }
        em{
            font-style: normal;
            font-weight: 400;
        }
        &.Mui-selected{
            //border-bottom:solid 3px #ffffff;
            color: rgba(255, 255, 255, 0.7);
            font-weight: 400;
        }
    }
    .MuiTabs-indicator{
        height:3px;
        background-color: rgba(255, 255, 255, .7);
    }
}
.playlist-tab{
  padding-top:22px;
  padding-bottom: 22px;
  background: #030008;
  
  button{
      font-size:14px;
      line-height: 17px;
      border-radius: 50px;
      border:solid 1px rgba(255, 255, 255, 0.70);
      padding:6px 12px;
      color:rgba(255, 255, 255, 0.70);
      background:transparent;
      text-transform: none;
      font-weight: 300;
      &:hover, &.active, &:active{
        background:rgba(255, 255, 255, 0.70);
        color:#000000;
        font-weight: 400;
        border-color: transparent;
      }
      
      & + button{
          margin-left:10px;
      }
      em{
          font-style: normal;
          font-weight: 400;
      }
      &.Mui-selected{
          //border-bottom:solid 3px #ffffff;
          color:#ffffff;
          font-weight: 400;
      }
      .MuiTouchRipple-root{
          display: none;
      }
  }
  .MuiTabs-indicator{
      height:3px;
      background-color: #ffffff;
  }
  .playlist-add{
    button{
      border:solid 1px var(--brand-white);
      border-radius: 50px;
      font-size:14px;
      line-height: 17px;
      padding:3px 12px 3px 12px;
      color:var(--brand-white);
      margin-left:21px;
      background:var(--brand-light-gray-1-33);
      font-weight: 300;
      &:hover{
        font-weight: 300;
      }
    }
  }
}
.playlist-tabs-1{
  display: flex; 
  align-items: center; 
  justify-content: flex-end;
  li{
    margin: 0; 
    padding: 0px; 
    width: auto;
    & + li{
      margin-left:10px;
    }

  }
}

.playlist-tab-2 {
  border-top: 1px solid #17151B;
  background: #0A0710;
  @media(max-width:899.98px){
    padding-bottom: 12px;
  }
  button {
    font-size: 16px;
    line-height: 19px;
    padding-top: 27px;
    padding-bottom: 27px;
    padding-left: 0px;
    padding-right: 0px;
    color: rgba(110, 115, 125, 0.7);
    text-transform: none;

    @media(max-width:899.98px){
      padding-top: 12px;
      padding-bottom: 12px;
    }
    
    & + button{
        margin-left:32px;
        @media(max-width:899.98px){
          margin-left:16px;
        }
    }
    em {
      font-style: normal;
      font-weight: 400;
    }
    &.Mui-selected {
      color:rgba(255, 255, 255, .70);
      font-weight: 400;
    }
  }
  .MuiTabs-indicator {
    height: 3px;
    background-color: #ffffff;
  }
}


 

.no-data-message{
  margin: 140px;
  color: #ffffff;
  font-style: normal;
  font-weight: 400;
  text-align: center;

 }


.left-menu-drawer{
  .MuiPaper-elevation{
    padding-top:75px;
    z-index: 1202;
    ul{
      li{
        border-top:solid 1px #474747;
        border-bottom:solid 1px #474747;
        color:#ffffff;
        font-size:16px;
        
        & + li{
          border-top:none;
          border-bottom:solid 1px #474747;
        }
        a{
          padding-top:18px;
          padding-bottom:18px;
          font-size:16px;
          color:rgba(255, 255, 255, .70);
          padding-left:60px;
          display: flex;
          align-items: center;
          column-gap: 13px;
          &:hover{
            background:#000000;
          }
          svg{
            color:rgba(255, 255, 255, .70);
          }

        }
      }
    }
  }
}

.filter-menu{
  overflow: hidden;
  position: relative;
  .filter-inner-bg{
    padding-left: 32px; 
    padding-right: 32px; 
    position:relative;
    padding-bottom:100px;
    height:100vh;
    overflow-y: auto;
  }
  .MuiPaper-elevation{
    &.MuiDrawer-paperAnchorRight{
      background:var(--brand-black);
      z-index: 1299;
      //padding-top: 75px;
      .filter-header{
        border-bottom:solid 1px rgba(255, 255, 255, 0.26);
        padding-left:0px;
        padding-right:0px;
        margin:0;
        .icon-close{
          fill:var(--brand-light-gray);
          cursor: pointer;
        }
        .filter-title{
          color:rgba(255, 255, 255, .7);
        }
      }
      .title-films-list{
        border-bottom:solid 1px rgba(255, 255, 255, 0.26);
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0.36px;
        padding-bottom: 12px;
        color:rgba(255, 255, 255, .7);
        margin-top:12px;
      }
      .search-box{
        border:solid 1px #8A8A8A;
        padding:3px 0px 3px 16px;
        border-radius: 20px;
        width:100%;
        font-size: 14px;
        .MuiOutlinedInput-root{
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
        .Mui-focused{
          input{
            box-shadow: none;
          }
        }
        fieldset{
          display: none;
        }
        input{
          padding:0;
          border:none;
          font-weight: 400;
          &::placeholder{
            font-weight: 300;
          }
          &:focus{
            box-shadow: none;
            outline: none;
          }
        }
        .MuiFormControl-root{
          background:transparent;
        }
      }
    }
    .movie-list-group{
      max-height: 180px;
      overflow-y: auto;
      &::-webkit-scrollbar {
        display: none;
      }
      &::-webkit-scrollbar {
        width: 5px;
        height: 8px;
        background-color: #aaa; /* or add it to the track */
      }
      &::-webkit-scrollbar-thumb {
        background: #000;
      }
      .movie-list{
        
        
        .movie-icon{
          width:45px;
          margin-right:5px;
          position: relative;
          flex:0 0 45px;
          img, video{
            max-width:100%;
          }
          .icon-play-video{
            position: absolute;
            top:50%;
            left:50%;
            transform: translate(-50%, -50%);
            width:20px;
            height:20px;

          }
        }
        &.film-list-card{
          border-radius: 8px !important;
          overflow: hidden !important;
          background:var(--brand-black-light);
          margin-bottom:12px;
        }
      }
      .card-description{
        padding-top:10px;
        padding-bottom: 10px;
        padding-right:15px;
        position: relative;
        &:hover{
          .checkbox-icon{
            display: block;
          }  
        }
        .checkbox-icon{
          position: absolute;
          top:50%;
          transform: translateY(-50%);
          right:8px;
          width:16px;
          height:16px;
          fill:var(--brand-white);
        }
      }
      .h3{
        font-size:12px;
        line-height: 15px;
        color:var(--brand-white);
        font-weight: 300;
        margin-bottom: 5px;
      }
      .h4{
        font-size:10px;
        line-height: 1;
        color:#7D8487;
        font-weight: 300;
        margin-bottom: 0px;
      }
    }
  }
  .flim-list-badges{
    li{
      button{
        font-size:14px;
        font-weight: 300;
        line-height:17px;
        padding:4px 8px;
        border-radius: 50px;
        text-transform: capitalize;
        & + button{
          margin-left:8px;
        }
      }
    }
  }
  .filter-button{
    position: absolute;
    bottom:0;
    left:0;
    width:100%;
    margin:0;
    background: var(--brand-black);
  }
  .video-hierarchical{
    padding: 0 !important;
    .count-box{
      display: flex;
      align-items: center;
      padding: 0 !important;
    }
    .v-title{
      font-size:16px;
      color:rgba(255, 255, 255, 0.7);
      line-height: 1;
    }
    .v-text-box{
      height: auto;
      margin:0;
      .MuiInputBase-input{
        border:solid 1px var(--brand-white);
        height:auto;
        padding:0;
        font-size:14px;
        color: var(--brand-white);
        border-radius: 50px;
        padding:4px 12px;
        
      }
    }
  }
}
.filter-menu .flim-list-badges{
  flex-wrap: wrap;
}
.version-box{
  
  .MuiAccordionSummary-root{
    padding:0;
    padding-bottom: 12px;

    border-bottom: solid 1px rgba(255, 255, 255, 0.26);
    min-height: inherit !important;
    .MuiAccordionSummary-content{
      margin-top:0px;
      margin-bottom: 0px;
      p{
        margin:0;
      }
    }
    .MuiSvgIcon-root{
      fill: white;
    }
  }
  .version-title{
    padding:0;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.36px;
    
    color: #ffffff;
    margin-top: 12px;
  }
  .MuiAccordionDetails-root{
    padding:30px 0 0;
  }
  
  .count-box{
    display: flex; 
    flex-direction: row; padding: 15px; 
    border-radius: 10px; 
    justify-content: space-between;
    padding:10px 10px !important;
    margin-top: 15px;;
  }
}



.left-menu-close-icon{
  color:white;
  position: absolute;
  top:29px;
  right:15px;
  fill: white;
  cursor: pointer;
}

.search-bar{
  display: flex;
  align-items: center;
  @media(max-width:999.98px){
    flex:1 0 auto;
    padding-left:30px;
    padding-right:30px;
  }
  @media(max-width:459.98px){
    flex:0 0 calc(100% - 110px);
    padding-left:15px;
    padding-right:15px;
  }
  > div{
    @media(max-width:999.98px){
      width:100%;
    } 
  }
  .MuiOutlinedInput-root{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width:100%;
    &.Mui-focused{
      box-shadow: none;
    }
  }
  .MuiTextField-root{
    margin: 8px;
    max-width:100%;
    border-radius: 100px;
    border: 1px solid var(--brand-gray-1);
    @media(min-width:768px){
      width: 562px;
    }
    &:hover{
      border: 1px solid var(--brand-gray-1);
    }
  }
  .search-btn{
    min-width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
  }
  fieldset{
    display: none;
  }

}
.profile-right-icon{
  position: relative;
  .profile-image-btn{
    border:none;
    background:transparent;
    padding:0;
    display: block;
  }
  .profile-drop-down{
    position: absolute;
    top:calc(100% + 36px);
    right:-24px;
    margin:0;
    padding:26px 16px 16px 16px;
    background:var(--brand-black);
    border-radius: 14px;
    &:before{
      content: "";
      width:24px;
      height:30px;
      position: absolute;
      top:-12px;
      right:29px;
      transform: rotate(-45deg);
      background:var(--brand-black);
    }
    li{
      list-style-type: none;
      & + li{
        margin-top:30px;
      }
      .profile-row{
        display: flex;
        align-items: center;
        column-gap: 12px;
        .profile-image{
          border-radius: 50%;
          width:38px;
          height:38px;
        }
        .profile-desc{
          h3{
            font-size: 16px;
            line-height: 19px;
            color:rgba(255, 255, 255, 0.7);
            margin:0 0 4px;
            font-weight: 500;
          }
          h4{
            font-size: 14px;
            line-height: 17px;
            color:var(--brand-light-gray);
            margin:0 0 4px;
            font-weight: 400;
          }
        }
      }
      .btn-logout{
        border:none;
        border-radius: 3px;
        background:var(--brand-dark-blue);
        padding:10px 0;
        font-size:16px;
        line-height: 19px;
        width:100%;
        cursor: pointer;
        color:var(--brand-white);
        font-weight: 400;
      }
    }
  }
}


.mui-switch{
  padding:0px !important;
  width:53px !important;
  height:24px !important;
  .MuiSwitch-switchBase{
    padding:0px !important;
  }
  .MuiSwitch-thumb{
    background:var(--brand-light-gray);
    width:24px;
    height:24px;
  }
  .MuiSwitch-track{
    background:var(--brand-light-gray-20) !important;
    height:20px;
    width:57px;
    border-radius: 10px;
    margin-left:0px;
    margin-top:2px;
    opacity: 1;
    &.Mui-checked{
      opacity: 1 !important;
    }
  }
  .MuiSwitch-switchBase{
    &.Mui-checked{
      transform: translateX(28px) !important;
      .MuiSwitch-thumb{
        background:var(--brand-dark-blue);
      }
    }
  }
}
.video-page{
  margin-top: 8px !important;
  .query-text-section{
    display: flex;
    margin-bottom: 18px;
    align-items: center;
    color: rgba(255, 255, 255, 0.7);
    text-transform: capitalize;
    font-size: 22px;
  }
  .video-box-header{
    display: flex;
    margin-bottom: 12px;
    align-items: center;
    color: #fff;
    justify-content: space-between;
    @media(max-width:1199.98px){
      flex-direction: column;
      row-gap: 12px;
      align-items: flex-start;
    }
    .left-section {
      .movie-meta{
        display: flex;
        align-items: center;
        p{
          text-transform: capitalize;
          font-size: 18px;
          color: rgba(255, 255, 255, 0.7);
        }
        .rating-box{
          display: flex;
          align-items: center;
          margin-left: 6px;
          padding: 3px 5px 3px 3px;
          border: 1px solid #fff;
          border-radius: 5px;
          height: 26px;
          img{
            width: 38px;
            margin-right: 4px;
          } 
          .rating-box-content{
            display: flex;
            align-items: flex-end;
            .rating-value{
              font-size: 17px;
              line-height: 17px;
              color:white;
              font-weight: 400;
             }
             .rating-max-value{
              font-size: 12px;
              line-height: 12px;
              color:white;
              font-weight: 400;
            }
          } 
        } 
      } 
      .result-section{
        text-align: left;
        font-size: 14px;
        font-weight: 300;
        letter-spacing: 0px;
        color: #989898;
        opacity: 1;
        margin-top: 5px;
      }
    }
    .right-section{
      align-self: flex-end;
      @media(max-width:1199.98px){
        align-self: flex-start;
      }
      .play-mode-toggle-group{
        background: #363738;
        height: 30px;
        border-radius: 15px;
        padding: 2px;
      }
      .play-mode-toggle-group{
        button{
          text-transform: capitalize;
          color: #989898;
          padding: 4px 10px;
          font-size: 12px;
          height: 26px;
          border: 1px solid #000;
          font-weight: normal;
          min-width: 100px;
          background:#0f0e11;
        }
      }
      .play-mode-toggle-group{
        button.Mui-selected{
          background-color: #272629;
          color: #fff;
        }
        .first{
          border-radius: 13px 0 0 13px;
        }
        .last{
          border-radius: 0 13px 13px 0;
        }
      }
    }
  }

  .video-box{
    width: 100%; 
    padding: 8px 8px 0px 8px; 
    border:solid 1px var(--video-box-border); 
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    .video-player{
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      overflow: hidden;
      display: block;
      video{
        min-height: 390px;
        display: block;
      }
    }
  }
  .video-player-controls{
    margin-top: -48px;
    margin-left: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    .play-btn{
      padding: 0;
      margin: 0;
    }
    .video-seeker-bar{
      flex:1 0 auto;
      position: relative;
      height: 25px;
      width: 100%;
      z-index: 2;
    }
    .icon-zoom{
      display: inline-block; 
      width: 16px; 
      height: 16px; 
      border: 2px dashed var(--brand-white); 
      margin: 0px;
    }
    .video-slider-seeker{
      color: #555758;
      .MuiSlider-rail{
        border-radius: 3px;
      }
      .MuiSlider-track{
        top:50%;
        transform: translateY(-50%);
        background:#fd0101;
      }
    }
  }
  .video-options{
    width: 100%; 
    height: 48px; 
    //background: linear-gradient(238deg, rgba(135, 201, 255, 0.15), rgba(152, 152, 152, 0.15));
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 0px;
    border-top: 1px solid #3E3E3E;
    padding-left:24px;
    padding-right:24px;
    .video-seeker-type{
      font-size:16px;
      line-height: 24px;
      font-weight: 400;
      display: flex;
      align-items: center;
      column-gap: 16px;
      color:var(--brand-white);
    }
    .video-seeker-item{
      display: flex;
      align-items: center;
    }
    .video-seeker-icon{
      display: inline-block; 
      width: 16px;
      height: 16px; 
      background: #17DC66; 
      margin-left: 4px; 
      margin-right: 4px; 
      border-radius: 10px;
      &.high{
        background: #17DC66; 
      }
      &.medium{
        background: #F88C07; 
      }
      &.low{
        background: #E6C51F; 
      } 
    }
  }
}


.video-page .video-player-controls {
  
}
.video-page .video-player-controls .cotrol-line {
  width: 100%;
  height: 1px;
  background-color: #fff;
  z-index: 1;
  opacity: .21;
}
.video-page .video-player-controls .video-player-controls-upper{
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
      flex: 1 0 auto;
  height: 48px;
  display: flex;
  align-items: center;
  z-index: 1;
  background-image: url("../public/images/player-control-bg.png");
}


.video-page .video-player-controls .video-player-controls-upper .play-btn {
  padding: 0;
  margin: 0 0 0 25px;
  width: 29px;
  height: 29px;
}
.video-page .video-player-controls .video-player-controls-upper .volume-box{
  z-index: 1;
  color: #fff;
  display: flex;
  margin-left: 20px;
  align-items: center;
  column-gap: 3px;
}
.video-page .video-player-controls .video-player-controls-upper .volume-box svg{
  cursor: pointer;
}
.video-page .video-player-controls .video-player-controls-upper .volume-box .volume-progress{
  cursor: pointer;
  accent-color: #fff;
  height: 4px;
  width: 66px;
  margin: 0;
  padding: 0;
}
.video-page .video-player-controls .video-player-controls-upper .controls-time{
  width: -webkit-fill-available;
    align-items: center;
    display: flex;
    color: #fff;
    font-size: 12px;
    margin-left: 10px;
}
.video-page .video-player-controls .video-player-controls-upper .right-controls{
  color: #fff;
  z-index: 1;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  padding-right: 25px;
}
.video-page .video-player-controls .video-player-controls-upper .right-controls .full-screen{
  cursor: pointer;
}
.video-page .video-player-controls .video-player-controls-upper .play-btn svg{
  width: 29px;
  height: 29px;
  font-size: 29px;
  color: #fff;
}
%playlist-container{
  .playlist-container{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    column-gap: 7px;
    > div{
      flex:1 0 auto;
    }
    .create-playlist{
      background:#272629;
      border-radius: 3px;
      width: 100%;
      height:46px;
      border:none;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      flex: 0 0 98px;
      cursor: pointer;
      .icon-plus{
        position: relative;
        margin-bottom: 4px;
        margin-top:4px;
        height: 12px;
        width: 12px;
        &:before, &:after{
          width:2px;
          height:12px;
          position: absolute;
          top:50%;
          left:50%;
          transform: translate(-50%, -50%);
          content: "";
          background:var(--brand-white);
        }
        &:after{
          width:12px;
          height: 2px;
        }
      }
      .create-text{
       font-size: 10px;
       color: var(--brand-white);
       line-height: 1.6;
      }
    }
    .btn-share{
      width: 100%;
      height:46px;
      flex: 0 0 46px;
      border:solid 1px var(--video-box-border);
      border-radius: 3px;
      background:transparent;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      img{
        max-width:100%;
      }
    }
    .add-to-playlist{
      .custom-file-upload {
        padding: 7px 8px 6px 8px;
        cursor: pointer;
        color:var(--brand-white);
        display: flex;
        align-items: center;
        justify-content: center;
        background:#0f0e11;
        border:dashed 1px #272629;
        border-radius: 4px;
        span{
          position: relative;
          padding-left:25px;
          &:before{
            content: "";
            background:url(./assets/icon-playlist-white.svg) 0 0 no-repeat;
            width:15px;
            height:15px;
            position: absolute;
            top:0;
            left:0;
          }
          strong{
            font-size:14px;
            line-height: 17px;
            font-weight: 300;
            color:var(--brand-white);
            display: block;
            margin-bottom: 2px;
          }
          em{
            font-size:10px;
            line-height: 12px;
            font-weight: 300;
            color:var(--brand-light-gray);
            display: block;
            font-style: normal;
          }
        }
      }
    }
  }
}
.image-description-box{
  @extend %playlist-container;
}
.video-desciption-box{
  border:solid 1px var(--video-box-border);
  border-radius: 8px;
  padding: 14px;
  h3{
    font-size:24px;
    line-height: 35px;
    color:var(--brand-white);
    font-weight: 400;
    margin:0 0 5px;
  }
  h4{
    font-size:14px;
    line-height: 17px;
    color:var(--brand-light-gray);
    font-weight: 400;
    margin:0 0 0px;
  }
  @extend %playlist-container;
  .video-desc-list{
    padding:0;
    margin:0;
    counter-reset: item;
    max-height: 598px;
    overflow-y: auto;
    overflow-x: hidden;
    margin-left: -14px;
    margin-right: -14px;
    &::-webkit-scrollbar {
      display: none;
    }
    &::-webkit-scrollbar {
      width: 5px;
      height: 8px;
      background-color: #aaa; /* or add it to the track */
    }
    &::-webkit-scrollbar-thumb {
      background: #000;
    }
    li{
      color:white;
      list-style-type: none;
      position: relative;
      counter-increment: item;
      cursor: pointer;
      padding-left:27px;
      padding-right: 14px;
      padding-top:8px;
      padding-bottom:8px;
      &.selected, &:hover{
        background:var(--brand-black-light);
        .icon-card-close{
          background-image:url(assets/iconCloseCardHover.svg);
        }
      }
      &:before{
        content: counter(item);
        position: absolute;
        top: 36px;
        left: 6px;
        transform: translateY(-50%);
        color:var(--brand-white);
        font-size: 14px;
        line-height: 17px;
        min-width: 16px;
        text-align: right;
      }
      .small-list{
        display: flex;
        align-items: flex-start;
        column-gap: 8px;
        margin-left: 0px;
      }
      .video-icon{
        max-width:100px;
        flex:1 0 auto;
        position: relative;
        .video-time{
          position: absolute;
          bottom:3px;
          right:3px;
          background:var(--brand-black);
          font-size:11px;
          line-height: 1;
          border-radius: 3px;
          padding:2px 4px;
          color:var(--brand-white);
        }
        &:hover{
          .icon-play-video{
            visibility: visible;
            opacity: 1;
            width:20px;
            height:20px;
            img{
              max-width:20px;
              max-height:20px;
            }
          }
        }
        img{
          max-width:100%;
          border-radius: 4px;
          height: 64px;
          object-fit: cover;
          display: block;
          width:100px;
        }
        .icon-play-video{
          position: absolute;
          top:50%;
          left:50%;
          transform: translate(-50%, -50%);
          width:0px;
          height:0px;
          transition: visibility 0s, opacity 0.5s linear;
          visibility: hidden;
          opacity: 0;
          img{
            max-width:0px;
            max-height:0px;
          }
        }
      }
      .video-desc{
        width:100%;
        h3{
          font-size:13px;
          line-height: 14px;
          letter-spacing: 0.23px;
          margin-bottom: 3px;
          color: rgba(255, 255, 255, 0.70);
          padding-right: 24px;
        }
        h4{
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-size:12px;
          line-height: 16px;
          letter-spacing: 0.14px;
          color: #6e737d;
          margin-bottom: 0;
          font-weight: 400;
        }
      }
      .icon-card-close{
        position: absolute;
        bottom:8px;
        right:19px;
        cursor: pointer;
        background:url(assets/iconCloseCard.svg) 0 0 no-repeat;
        width:20px;
        height: 20px;
      }
      .icon-full-desc{
        position: absolute;
        top:15px;
        right:24px;
        color:var(--brand-white);
        
        svg{
          position: absolute;
          top:50%;
          left:50%;
          transform: translate(-50%, -50%);
          transition: all 0.1s ease-in;
        }
        &.active{
          svg{
            transform: translate(-50%, -50%) rotate(-90deg);
          }
          
        }
      }
      .full-desc{
        font-size:12px;
        line-height: 16px;
        color:rgba(255, 255, 255, .7);
        font-weight: 300;
        display: none;
        margin-bottom: 0px;
        padding-top:10px;
      }
    }
  }
}

.video-desciption-box{
  .video-desc-list{
    li {
      .video-desc {
        h4 {
          .video-desc-inner-content{
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            width: 100%;
            .first-line{
              display: flex;
              align-items: center;
              width: 100%;
              margin-bottom: 1px;
              .movie{
                width: 40%;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
              }
              .cateogry-name{
                margin-left: 5px;
                padding-left: 5px;
                width: 60%;
                height: 16px;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                /* border-left: 1px solid #989898; */
              }
            }
            .second-line{
              display: flex;
              align-items: center;
              width: 100%;
            }
            .time{
              display: flex;
              min-width:92px;
            }
          }
        }
      }
    }
    li{
      &.timeline-right-list{
        margin: 6px 0px;
        &:hover{
          .icon-play-video {
            visibility: visible;
            opacity: 1;
            width: 20px;
            height: 20px;
            img {
              max-width: 20px;
              max-height: 20px;
            }
          }
        }
      }
    }
  }
}


.common-dailog{
  h3{
    margin:0 0 22px;
    padding:0 0 18px;
    font-size:18px;
    font-weight: 400;
    position: relative;
    color:rgba(255, 255, 255, 0.7);
    &:after{
      content: "";
      position: absolute;
      bottom:0;
      left:-16px;
      right:0;
      height:1px;
      width:calc(100% + 32px);
      background:var(--brand-light-gray);
    }
  }
  ul{
    margin:0 0 10px;
    padding:0;
    
    li{
      list-style-type: none;
      margin-bottom: 19px;
      font-size:14px;
      line-height: 1;
      color:rgba(255, 255, 255, 0.7);
      .listhead{
        display: flex;
        align-items: center;
        padding-left:26px;
        font-size:14px;
        .control_indicator{
          top:50%;
          transform: translateY(-50%);
        }
      }
      
    }
  }

  .sep{
    position: relative;
    &:before{
      content: "";
      position: absolute;
      bottom:0;
      left:-16px;
      right:0;
      height:1px;
      width:calc(100% + 32px);
      background:var(--brand-light-gray);
    }
  }

  .btn-blue{
    background:var(--brand-dark-blue);
    border-radius: 21px;
    font-size:14px;
    line-height: 1.2;
    padding:12px;
    width:100%;
    text-transform: none;
    font-weight: 400;
  }
  .btn-none{
    color:var(--brand-dark-blue);
    position: relative;
    font-size:16px;
    line-height: 1.2;
    background: transparent;
    border:none;
    margin-bottom: 22px;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding:0;
    span{
      display: inline-block;
      width:16px;
      position: relative;
      height:10px;
      margin-right: 11px;
      &:before{
        content:"";
        position: absolute;
        top:50%;
        left:50%;
        transform: translate(-50%, -50%);
        width:2px;
        height: 16px;
        background:var(--brand-dark-blue);
      }
      &:after{
        content:"";
        position: absolute;
        top:50%;
        left:50%;
        transform: translate(-50%, -50%);
        width:16px;
        height: 2px;
        background:var(--brand-dark-blue);
      }
    }
  }
  .btn-cancel{
    width:34px;
    position: absolute;
    height:34px;
    top:-10px;
    right:-10px;
    background:var(--brand-white);
    cursor: pointer;
    border-radius: 50%;;
    span{
      position: absolute;
      top:50%;
      left:50%;
      transform:rotate(-45deg) translate(-50%, -50%);
      &:before{
        content:"";
        position: absolute;
        top:50%;
        left:50%;
        transform: translate(-50%, -50%);
        width:2px;
        height: 16px;
        background:var(--brand-black-light);
      }
      &:after{
        content:"";
        position: absolute;
        top:50%;
        left:50%;
        transform: translate(-50%, -50%);
        width:16px;
        height: 2px;
        background:var(--brand-black-light);
      }
    }
  }
  .form-control{
    height:32px;
    border:none;
    border-bottom:solid 1px var(--brand-light-gray);
    background:transparent;
    font-size:16px;
    color:var(--brand-light-gray);
    margin-bottom: 30px;
    width:100%;
    &:focus{
      box-shadow: none;
      outline: none;
    }
    &::placeholder{
      color:rgba(255,255,255,0.19);
    }
  }
  .inner-dailog{
    min-height: 150px;
  }
}



// Box to contain form controls

.listhead {
  display: block;
  position: relative;
  padding-left: 30px;
  margin: 0 0 15px 0;
  cursor: pointer;
  font-size: 18px;
  input{
    vertical-align:top;
    margin-right:10px;
    position: absolute;
    z-index: -1;
    opacity: 0;
  }
  .control_indicator{
    position: absolute;
    top: 2px;
    left: 0;
    height: 16px;
    width: 16px;
    background: transparent;
    background:url(assets/playlistUnchekced.svg) 0 0 no-repeat;
  }
  input{
    &:disabled{
      & ~ .control_indicator {
        opacity: 0.5;
        pointer-events: none;
      }
    }
    &:checked{
      & ~ .control_indicator{
        background-image:url(assets/playlistChekced.svg);
      }
    }
  }  
}



//first time for home page only
//slide-item needs to remove
.card-item{
  width: 100%; 
  background-size: 100% 300px; 
  border-radius: 2px;
  display: flex; 
  flex-direction: column; 
  position: relative;
  overflow: hidden;
  .confidence-level{
    position: absolute;
    top:10px;
    left:10px;
    font-size:12px;
    line-height: 1;
    border-radius: 16px;
    padding:5px 13px;
    color:var(--brand-black);
    z-index: 1;
    &.alternative{
      position: static;
      display: inline-block;
      margin-bottom: 16px;
    }
  }
  .card-time{
    position: absolute;
    bottom:80px;
    right:10px;
    background:var(--brand-black);
    font-size:12px;
    line-height: 1;
    border-radius: 3px;
    padding:4px 6px;
    color:var(--brand-white);
    z-index: 2;
  }
  .card-img-bg{
    width: 100%; 
    min-height: 326px;  
    margin-bottom:0px;
    border-radius: 2px;
    @media(max-width:1366.98px){ 
      min-height: 280px;
    }
    &:before{
      position: absolute;
      top:0;
      right:0;
      bottom:0;
      left:0;
      background: transparent linear-gradient(180deg, #00000014 0%, #0000001C 55%, #000000D6 100%) 0% 0% no-repeat padding-box;
      content: "";
      z-index: 1;
    }
    &:after{
      position: absolute;
      top:0;
      right:0;
      bottom:0;
      left:0;
      box-shadow: inset 0px 0px 22px #0000009E;
      opacity: .5;
      content: "";
      z-index: 1;
    }

  }
  &:hover{
    .backdrop-down{
      .h3{
        font-size: 0;
        color: transparent;
      }
    }
    .backdrop-up{
        top:0;
        .h2{
          min-height: inherit;
          .dailog-still-title{
            display: none;
          }
        }
    }
  }
  .backdrop-up{
      position: absolute;
      z-index: 2;
      top:calc(100% - 63px);
      height:100%;
      transition:all .5s ease;
      width:100%;
      height: 0px;
      overflow: hidden;
      height: 100%;
      overflow: visible;
      padding:10px 16px 12px;
      background:var(--bg-black-26);
      backdrop-filter: blur(8px);
  }
  .h2{
    font-size:14px;
    line-height: 21px;
    color:rgba(255, 255, 255, .85);
    margin:0 0 8px;
    font-weight: 400;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    min-height: 46px;
    .dailog-still-title{
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
  .h3{
    font-size:14px;
    line-height: 17px;
    color:white;
    margin:0 0 0px;
    font-weight: 300;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .h4{
    font-size:11px;
    line-height: 15px;
    color:rgba(255, 255, 255, .85);
    font-weight: 300;
    margin:0 0 12px;
  }
  .h5{
    font-size:13px;
    line-height: 16px;
    color:white;
    font-weight: 300;
    margin:0 0 16px;
    strong{
      font-weight: 600;
    }
  }
  .p{
      font-size:12px;
      line-height: 16px;
      color:rgba(255, 255, 255, .85);
      font-weight:300;
      margin:0;
      text-align: justify;
  }
  .description-text{
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 7;
    -webkit-box-orient: vertical;
    @media(max-width:1366.98px){ 
      -webkit-line-clamp: 5;
    }
  }
  .read-more{
    font-weight: 400;
    cursor: pointer;
    text-align: right;
    position: relative;
    padding-right:10px;
    display: flex;
    justify-content: flex-start;
    text-decoration: underline;
    text-transform: capitalize;
  }
  .mb-16{
      margin-bottom: 16px;
  }
  .mb-12{
      margin-bottom: 12px;
  }
  .mb-8{
      margin-bottom: 8px;
  }
  .mb-0{
      margin-bottom: 0px;
  }
  .title-group{
      display: flex;
      column-gap: 10px;
      span{
          display: block;
          &.movie-name{
            text-transform: uppercase;
          }
          & + span{
              position: relative;
              &:before{
                  content:"";
                  position: absolute;
                  top:50%;
                  left:-5px;
                  width:1px;
                  height:10px;
                  background: #ffffff;
                  transform: translateY(-50%);
              }
          }
      }
  }
  .buttons-group{
    @extend %buttons-group;
  }
  .badges{
      button{
          border-radius: 50px;
          border: 1px solid rgba(255, 255, 255, 0.85);
          color: rgba(255, 255, 255, 0.85);
          text-transform: capitalize;
          margin-right: 8px;
          font-size:12px;
          font-weight: 300;
          padding:1px 11px;
          min-width: inherit;
          margin-bottom: 16px;
          &:hover{
              border: 1px solid rgba(255, 255, 255, 0.85)
          }
      }
  }
  .card-top-row{
      padding:16px 16px 0 16px;
      position: relative;
      z-index: 2;
  }
}
.buttons-group-svgicons{
  @extend %buttons-group;
  
}

%buttons-group{
  display: flex;
  align-items: center;
  column-gap: 18px;
  button{
    padding:0;
    &:hover, &.active{
      svg{
        path{
          fill:#ffffff;
        }
      }
    }
    svg{
      path{
        fill:#B3B3B5;
      }
    }
    &.btn-stroke{
      padding:0;
      &:hover, &.active{
        svg{
          path{
            stroke:#ffffff;
          }
        }
      }
      svg{
        path{
          stroke:#B3B3B5;
        }
      }
    }
    &.selected{
      svg{
        path{
          fill:#e32424;
        }
      }
    }
  }
}


.bug-dailog{
  .MuiDialog-paper{
    background: transparent linear-gradient(180deg, #222226 0%, #080809 100%) 0% 0% no-repeat padding-box !important; 
  }
  #customized-dialog-title{
    background:transparent;
    background: transparent linear-gradient(90deg, rgba(152,152,152, .15) 0%, rgba(135,201,255,.15) 100%) 0% 0% no-repeat padding-box;
  }
}
.page-min-height{
  min-height: calc(100vh - 402px);
}
.page-min-height-1{
  min-height: calc(100vh - 144px);
}


.index-page{
  .app-header{
    padding-top:21px;
    padding-bottom:21px;
    @media(max-width:1199.98px){
      padding-top:15px;
      padding-bottom:15px;
    }
    .app-header-inner{
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    
    .app-menu{
      ul{
        padding:0;
        margin:0;
        display: flex;
        align-items: center;
        justify-content: space-between; 
        column-gap:32px;
        @media(max-width:767.98px){
          column-gap:16px;
        }
        li{
          list-style-type: none;
          a{
            font-size:16px;
            color:#fff;
            line-height: 19px;
            text-decoration: none;
          }
          
        }
      }
    }
  }
  .app-banner{
    min-height: 648px;
    height: calc(100vh - 132px);
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: relative;
    @media(max-width:1199.98px){
      height: calc(100vh - 120px);
      min-height: 448px;
    }
    @media(max-width:767.98px){
      height: calc(100vh - 129px);
    }
    img{
      object-fit: cover;
      display: block;
      position: absolute;
      top:0;
      left:0px;
      width:100%;
      height:100%;
    }
    .app-baner-content{
      position: absolute;
      top:50%;
      transform: translateY(-50%);
      max-width:500px;

    }
    h1{
      font-family: "oswald", sans-serif !important;
      font-size:60px;
      font-weight: 700;
      line-height: 1.28;
      margin:0 0 30px;
      color:var(--brand-white);
      @media(max-width:1199.98px){
        font-size:48px;
      }
      @media(max-width:991.98px){
        font-size:32px;
        font-weight: 400;
        margin:0 0 15px;
      }
    }
    h4{
      font-size:19px;
      font-weight: 600;
      line-height: 1;
      color:rgba(255, 255, 255, .5);
      margin:0 0 9px;
      @media(max-width:991.98px){
        font-size:14px;
      }
    }
    h5{
      font-size:30px;
      font-weight: 600;
      font-style: italic;
      line-height: 1.56;
      color:rgba(255, 255, 255, 1);
      margin:0 0 54px;
      padding-left:22px;
      border-left:solid 5px #2674e3;
      @media(max-width:991.98px){
        font-size:22px;
        margin:0 0 30px;
      }
    }
    .btn-contact{
      font-size:16px;
      font-weight: 600;
      padding:12px 48px;
      text-transform: uppercase;
      line-height: 1;
      color:rgba(255, 255, 255, 1);
      margin:0 0 9px;
      border:solid 1px var(--brand-white);
      border-radius: 3px;
      background:transparent;
    }
  }
  .app-footer{
    background:#0c0c0c;
    font-size:14px;
    padding-top:15px;
    padding-bottom:15px;
    color:var(--brand-white);
    @media(max-width:767.98px){
      padding:0;
    }
    .app-footer-inner{
      
      display: flex;
      align-items: center;
      justify-content: center;
      @media(max-width:767.98px){
        height:60px;
      }
    }
  }
}
.position-relative{
  position: relative;
}
.autocomplete-paper{
  background-color:#0C0C0C  !important;
  padding: 0px !important;
  width: 562px !important;
  margin: 0px !important;
  margin-left: -7px !important;
  border: 1px solid #474747 !important;
  border-top: 0px !important;
  border-radius: 0px 0px 18px 18px  !important;
  font-weight: 300 !important;
}

.search-bar .MuiTextField-root:hover {
  //border: 1px solid var(--brand-gray-1) !important;
  box-shadow: none;
}

.searchInputTextBox{
  padding-left: 6px !important;
  padding-right: 12px !important;
  border-radius: 21px !important;
  //border: 1px solid var(--brand-gray-1) !important;
}

.Mui-expanded .searchInputTextBox {
  border-radius: 21px 21px 0px 0px !important;
  border-bottom: 0px;
}

.MuiAutocomplete-noOptions {
  color: #ffffff!important;
  font-weight: 300;
}

.Mui-focusVisible{
  background-color: #1E1F20 !important;
}

.MuiAutocomplete-option[aria-selected="true"]{
  background-color: inherit !important;
}

.MuiAutocomplete-option[aria-selected="true"].Mui-focused{
  background-color: inherit !important;
}


.empty-container{
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px;
  flex-direction: column;
  height: 64vh;
  font-size: 24px;
}

.swiper {
  width: 100%;
  height: 100%;
  &.homebanner-swiper{
    .swiper-wrapper{
      .swiper-slide{
      &:after{
        background: rgb(8,2,16);
        background: transparent linear-gradient(0deg, #070110 0%, #070110 5%, #070110 15%, transparent 100%) 0% 0% no-repeat padding-box;
        opacity: 1;
        position: absolute;
        bottom:0px;
        height:calc(var(--banner-overlay-height) * 1);
        content: "";
        left:0px;
        width:100%;
        z-index: 1;
      }
      &:before{
        background: rgb(8,2,16);
        background: transparent linear-gradient(89deg, #000000 0%, #00000085 48%, #00000000 100%) 0% 0% no-repeat padding-box;
        opacity: 1;
        position: absolute;
        bottom:0px;
        height:100%;
        content: "";
        left:0px;
        width:30%;
        z-index: 1;
      }
    }
    }
  }
  .swiper-pagination{
    bottom:calc( var(--banner-overlay-height) * .6)!important;
    @media (max-width: 899.98px) {
      bottom:0px !important;
    }
  }
  .swiper-pagination-bullet{
    background:var(--brand-white);
    opacity: 0.4;
    &.swiper-pagination-bullet-active{
      opacity: 1;
      width: 32px;
      border-radius: 4px;
    }
  }
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: transparent;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}
.banner-text{
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 64%;
  width: 482px;
  text-align: left;
  letter-spacing: 0.32px;
  color: #D2D2D2;
  opacity: 1;
  padding-bottom: calc(var(--banner-overlay-height) * .5);
  z-index: 9;
}
.banner-text .banner-desc{
  margin-top:20px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.banner-text button{
  border: 1px solid #FFFFFF;
  border-radius: 3px;
  opacity: 1;
  color: #FFFFFF;
  width: 195px;
  height: 42px;
  margin-top: 44px;
  @media(max-width:767.98px){
    margin-top: 24px;
  }
}
.banner-text button:hover{
  border: 1px solid #FFFFFF;
}

.swiper-slide{
  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.swiper-pagination-bullet {
  background: rgba(255, 255, 255, 1);
}

.swiper-vertical > .swiper-pagination-bullets, .swiper-pagination-vertical.swiper-pagination-bullets {
  right: 83px;
}

.swiper-pagination-bullet-active {
  width: 32px;
  border-radius: 6px;
  background: #FFFFFF;
}
.histogram-bg-parent{
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  column-gap: 3px;
  .histogram-bg{
    flex:0 0 6px;
    background:#272629;
  }
  img{
    height: 50px !important;
    width:89px !important;
    border-radius: 1px;
  }
}

.cursor-pointer{
  cursor: pointer;
}
.card-dailog-box{
  .video-img-box{
    img, video{
      height: 100%;
      width:100%;
      object-fit: cover;
      border-radius: 16px;
    }
  }
  .MuiDialog-paper{
    width: 1208px;
    max-width: calc(100% - 60px);
    height: 523px;
    max-height: calc(100% - 60px);
    border-radius: 22px;
    background: #000000;
    position: relative;
    overflow: initial;
  }
  .MuiDialog-container{
    background: rgba(42,42,42,0.6);
  }
  h4{
    font-size:16px;
    line-height: 1.2;
    color:rgba(255, 255, 255, .70);
    font-weight: 400;
    margin:0 0 15px;
  }
  h3{
    font-size:24px;
    line-height: 1.458;
    color:rgba(255, 255, 255, .70);
    font-weight: 400;
    margin:0 0 15px;
  }
  ul.year-name{
    padding:0px;
    margin:0px 0 14px;
    li{
      list-style-type: none;
      font-size:16px;
      line-height: 19px;
      color:rgba(255, 255, 255, .70);
      font-weight: 300;
      display: inline-block;
      &.movie-name{
        font-weight: 500;
      }
      & + li{
        padding-left:30px;
        position: relative;
        &:before{
          content: "";
          background:var(--brand-white);
          width:1px;
          height:12px;
          position: absolute;
          top:50%;
          transform: translateY(-50%);
          left:15px;
        }
      }
    }
  }
  .close-dailog{
    position: absolute;
    top:-17px;
    right:-17px;
    background:url(assets/icon-close-large.svg) 0 0 no-repeat;
    width:34px;
    height:34px;
    cursor: pointer;
    border:none;
  }
  .icon-btns-group{
    display: flex;
    align-items: center;
    justify-content: space-between;
    .icon-btns{
      display: flex;
      column-gap: 18px;
    }
  }
  .description{
    margin-top:24px;
    p{
      font-size: 16px;
      color:rgba(255, 255, 255, .70);
      margin: 0 0 15px;
    }
  }
}
.filter-condidence-level{
  height:4px;
  .MuiSlider-track{
    background: rgba(152, 152, 152, 1) !important;
    border-radius: 0px !important;
  }
  .MuiSlider-mark{
    display: none;
  }
}
.MuiAutocomplete-popper{
  .MuiPaper-rounded {
    background:#070110;
    border-color:var(--brand-gray-1) !important;
  }
  .MuiAutocomplete-listbox{
    background:#070110;
    li{
      color:rgba(255, 255, 255, 0.7);
    }
  }
}