.fullpage-loader-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    width: 100%;
    height: 100vh;
    z-index: 9999;
    background: rgba(0, 0, 0, 0.75);
}

.fullpage-loader-container .loader {
    color: #FFF;
}