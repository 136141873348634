.about-page {
  padding-bottom: 70px;
  .about-banner {
    margin-bottom: 64px;
    position: relative;
    img {
      display: block;
      max-width: 100%;
      min-width: 100%;
    }
    .about-heading {
      position: absolute;
      top:50%;
      left:50%;
      transform: translate(-50%, -50%);
      font-size:36px;
      line-height: 1;
      color:#fff;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }


  

  h3{
    font-size:30px;
    line-height: 1.2;
    color:rgba(255, 255, 255, 0.7);
    margin: 0 0 24px;
    font-weight: 500;
  }

  p{
    font-size:16px;
    line-height: 26px;
    color:rgba(255, 255, 255, 0.7);
    margin: 0 0 36px;
  }
 
}


.img-responsive{
  max-width:100%;
}