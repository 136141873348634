.contact-page {
  padding-bottom: 70px;
  .contact-banner {
    padding-bottom: 66px;
    img {
      display: block;
      max-width: 100%;
    }
  }
  .contact-row {
    display: flex;
    align-items: center;
    padding-left: 44px;
    padding-right: 44px;
    .contact-info {
      padding: 74px 44px 44px 44px;
      background: #1E1F20;
      @media (max-width: 899.98px) {
        margin-bottom: 50px;
      }
      h3 {
        font-size: 23px;
        line-height: 35px;
        font-weight: 500;
        color:rgba(255, 255, 255, 0.7);
        margin: 0 0 20px;
      }
      p {
        font-size: 14px;
        line-height: 1.3;
        font-weight: 500;
        color: #989898;
        margin: 0 0 40px;
      }
      ul {
        padding: 0px;
        margin: 0px;
        li {
          list-style-type: none;
          color:rgba(255, 255, 255, 0.7);
          padding-left: 36px;
          background: url(../../assets/icon-mail.svg) 0 50% no-repeat;
          font-size: 16px;
          line-height: 27px;
          display: flex;
          align-items: center;
          margin-bottom: 30px;
          &.second {
            background-image: url(../../assets/icon-call.svg);
          }
          &.third {
            background-image: url(../../assets/icon-location.svg);
          }
        }
      }
    }
    .contact-form {
      .MuiTextField-root {
        .MuiFormLabel-root {
          position: static;
          color: rgba(152, 152, 152, 1);
          transform: none;
          font-size: 13px;
        }
        input {
          border-bottom: solid 1px rgba(255, 255, 255, 0.7);
          padding: 8px 0px;
          color:rgba(255, 255, 255, 0.7);
          &::placeholder {
            color: rgba(255, 255, 255, 0.5);
            opacity: 1;
          }
        }
        fieldset {
          display: none;
        }
      }
      .btn-submit {
        background: #2674e3;
        font-size: 16px;
        line-height: 19px;
        color: #fff;
        cursor: pointer;
        min-width: 190px;
        padding: 12px;
        border-radius: 3px;
        text-transform: none;
        font-weight: 400;
      }
    }
  }
}
