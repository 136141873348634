.sub-container-assets {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    min-height: calc(100vh - 290px);
}

.assets-tab {
    background: #030008;
    padding-top: 22px;
    padding-bottom: 22px;
}

.icon-container {
    display: flex;
    align-items: center;
}

.subheader-text {
    margin-left: 11px!important;
    color: #FFFFFF;
    font-size: 22px!important;
}

.assets-tab-2 {
    border-top: 1px solid #17151B;
    background: #0A0710;  
}

.assets-tab-2 button {
    font-size: 16px;
    line-height: 19px;
    padding-top: 27px;
    padding-bottom: 27px;
    padding-left: 0px;
    padding-right: 0px;
    color: rgba(110, 115, 125, 0.7);
    text-transform: none;
}

.assets-tab-2 button + button {
    margin-left:32px;
}

.assets-tab-2 button.Mui-selected {
    color:rgba(255, 255, 255, .70);
    font-weight: 400;
}

.assets-tab-2 button em {
    font-style: normal;
    font-weight: 400;
}

.assets-tab-2 .MuiTabs-indicator {
    height: 3px;
    background-color: #ffffff;
}

.assets-body {
    padding-top: 29px;
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
}

