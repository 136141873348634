.sub-container-generated-video {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    min-height: calc(100vh - 290px);
}

.generated-video-tab {
    background: #030008;
    padding-top: 22px;
    padding-bottom: 22px;
}

.icon-container {
    display: flex;
    align-items: center;
}

.subheader-text {
    margin-left: 11px!important;
    color: #FFFFFF;
    font-size: 22px!important;
}

.generated-video-body {
    padding-top: 35px;
}

.generated-video-body .gvb-inner {
    margin-left: auto;
    margin-right: auto;
    width: fit-content;
}

.gvb-inner-top {
    display: flex;
    justify-content: space-between;
}

.gvb-inner-top .first {
    display: flex;
    max-width: 950px;
}

.gvb-inner-top .first .image-box img {
    cursor: pointer;
}

.gvb-inner-top .first .edit-icon-box {
    cursor: pointer;
    margin-left: 16px;
}

.gvb-inner-top .first .rename-field-box {
    display: flex;
    margin-bottom: 10px;
}

.gvb-inner-top .first .rename-textbox-field {
    width: 750px;
}

.gvb-inner-top .first .rename-textbox-field .MuiInputBase-root {
    color: rgba(255, 255, 255, 0.7);
    font-size: 18px;
}

.gvb-inner-top .first .rename-textbox-field .MuiOutlinedInput-notchedOutline {
    border-color: #6E737D;
}

.gvb-inner-top .first .rename-check-box {
    border-radius: 3px;
    border: 1px solid #6E737D;
    padding: 8px 11px;
    background: #272629;
    cursor: pointer;
    margin-left: 9px;
}

.gvb-inner-top .update-button-container {
    border: 1px solid #5B5B5B;
    border-radius: 3px;
    background: rgba(0,0,0,0.5);
    color: #FFFFFF;
    padding: 6px 8px 3px 8px;
    display: inline-block;
    cursor: pointer;
}

.gvb-inner-top .update-button {
    color: #FFFFFF;
}

.rename-check-box img {
    width: 17px;
}

.gvb-inner-top .first .rename-cancel-box {
    border-radius: 3px;
    border: 1px solid #6E737D;
    padding: 8px 12px;
    background: #272629;
    cursor: pointer;
    margin-left: 9px;
}

.rename-cancel-box img {
    width: 15px;
}

.generated-video-name {
    color: rgba(255, 255, 255, 0.7);
    font-size: 18px;
}

.generated-video-details {
    font-size: 12px!important;
    color: #6E737D;
}

.gvb-video-wrapper {
    border: 1px solid #5B5B5B;
    border-radius: 0px 0px 8px 8px;
    background: rgba(0,0,0,0.5);
    width: 1148px;
    height: 607px;
    padding: 10px;
    margin-top: 16px;
}

.gvb-video-inner {
    border-radius: 8px;
    background: #474747;
    width: 100%;
    height: 100%;
}

.gvb-video-content-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
    width: 100%;
    height: 100%;
    padding: 19px;
}

.genvid-metadata {
    border: 1px solid #6E737D;
    border-radius: 9px;
    color: #C5C5C5;
    background: #272629;
    padding: 16px 14px;
    margin-top: 40px;
    width: 1148px;
}

.genvid-textarea-box-readonly {
    margin-top: 45px;
}

.genvid-textarea-readonly {
    background: #272629;
    width: 1148px;
}

.genvid-textarea-readonly .MuiOutlinedInput-root {
    border: 1px solid #6E737D;
    border-radius: 9px;
    color: #C5C5C5;
}

.genvid-textarea-readonly .MuiOutlinedInput-input::placeholder {
    color: rgba(110, 115, 125, 0.7);
}

.genvid-textarea-readonly .MuiOutlinedInput-notchedOutline {
    border-color: #6E737D!important;
    border-width: 0px!important;
}

.gvb-video-wrapper .video-player-controls {
    margin-top: -54px;
    margin-left: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.gvb-video-wrapper .video-player {
    overflow: hidden;
}

.gvb-video-wrapper .video-player-controls-upper{
    -webkit-box-flex: 1;
    -ms-flex: 1 0 auto;
        flex: 1 0 auto;
    height: 48px;
    display: flex;
    align-items: center;
    z-index: 1;
    background-image: url("../../../public/images/player-control-bg.png");
}

.gvb-video-wrapper .video-player-controls-upper .play-btn {
    padding: 0;
    margin: 0 0 0 25px;
    width: 29px;
    height: 29px;
}

.gvb-video-wrapper .video-player-controls-upper .volume-box{
  z-index: 1;
  color: #fff;
  display: flex;
  margin-left: 20px;
  align-items: center;
  column-gap: 3px;
}

.gvb-video-wrapper .video-player-controls-upper .volume-box svg{
  cursor: pointer;
}

.gvb-video-wrapper .video-player-controls-upper .volume-box .volume-progress{
  cursor: pointer;
  accent-color: #fff;
  height: 4px;
  width: 66px;
  margin: 0;
  padding: 0;
}

.gvb-video-wrapper .video-player-controls-upper .controls-time{
  width: -webkit-fill-available;
    align-items: center;
    display: flex;
    color: #fff;
    font-size: 12px;
    margin-left: 10px;
}

.gvb-video-wrapper .video-player-controls-upper .right-controls{
  color: #fff;
  z-index: 1;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  padding-right: 25px;
}

.gvb-video-wrapper .video-player-controls-upper .right-controls .full-screen{
  cursor: pointer;
}

.gvb-video-wrapper .video-player-controls-upper .play-btn svg{
  width: 29px;
  height: 29px;
  font-size: 29px;
  color: #fff;
}

.gvb-video-wrapper .video-player-controls .video-seeker-bar {
    flex:1 0 auto;
    position: relative;
    height: 6px;
    width: 100%;
    z-index: 2;
}

.gvb-video-wrapper .video-player-controls .video-seeker-bar .video-slider-seeker {
    color: #555758;
}

.gvb-video-wrapper .video-player-controls .video-seeker-bar .video-slider-seeker .MuiSlider-rail {
    border-radius: 3px;
}

.gvb-video-wrapper .video-player-controls .video-seeker-bar .video-slider-seeker .MuiSlider-track {
    top:50%;
    transform: translateY(-50%);
    background:#fd0101;
}

.generated-video-body .gvb-inner .bottom-section {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 16px;
}

.generated-video-body .gvb-inner .bottom-section .share-user-list {
    padding: 0px!important;
    width: 100%;  
    border-top: unset;
    margin-top: unset!important;
}

