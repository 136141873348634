
.sub-container-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 35px;
}

.gen-vid-heading {
    font-size: 24px!important;
    color: #FFFFFF;
}

.genvid-movie_name-box {
    margin-top: 30px;
    width: 1148px;
    display: flex;
    justify-content: space-between;
    column-gap: 16px;
}

.genvid-movie_name-box .genvid-version-select {
    width: 122px;
}

.genvid-movie_name-box .genvid-version-select .MuiSelect-select {
    padding: 8px 14px;
}

.genvid-movie_name-box .genvid-clip-length-preference-select {
    width: 160px;
}

.genvid-movie_name-box .genvid-clip-length-preference-select .MuiSelect-select {
    padding: 8px 14px;
}

.genvid-movie_name-box .MuiAutocomplete-root {
    width: 800px;
    flex-grow: 1;
}

.genvid-movie_name-box .movie_name .MuiOutlinedInput-notchedOutline {
  border-color: #6E737D!important;
  border-radius: 9px;
}

.genvid-movie_name-box .movie_name .MuiButtonBase-root {
    color: #6E737D;
}

.genvid-movie_name-box .movie_name .MuiInputBase-root {
  color: #6E737D;
}

.genvid-textarea-box {
    margin-top: 16px;
}

.genvid-textarea {
    background: #030008;
    width: 1148px;
}

.genvid-textarea .MuiOutlinedInput-root {
    border: 1px solid #6E737D;
    border-radius: 9px;
    color: rgba(255, 255, 255, 0.7);
}

.genvid-textarea .MuiOutlinedInput-input::placeholder {
    color: rgba(110, 115, 125, 0.7);
}

.genvid-button-container {
    margin-top: 24px;
    display: flex;
    justify-content: center;
}

.genvid-cancel-button {
    margin-right: 16px!important;
    font-size: 16px!important;
    text-transform: initial!important;
    font-weight: 300!important;
    width: 150px;
    color: #FFFFFF!important;
    border: 1px solid #474747!important;
    background-color: #070110!important;
}

.generate-button {
    width: 195px;
    background-color: #2674E3!important;
    font-size: 16px!important;
    text-transform: initial!important;
    font-weight: 300!important;
}
