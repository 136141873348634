.page-not-found-container {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.page-not-found-inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    width: fit-content;
}

.page-not-found-title {
    font-size: 18px!important;
    color: #FFFFFF;
    margin-top: 32px!important;
    padding-bottom: 6px;
}

.page-not-found-subtitle {
    font-size: 14px!important;
    color: #6E737D;
    padding-bottom: 6px;
    text-align: center;
}

.page-not-found-back-button-container {
    margin-top: 24px;
    margin-bottom: 16px;
}

.page-not-found-back-button {
    width: 207px;
    background-color: #2674E3!important;
    font-size: 16px!important;
    text-transform: initial!important;
    font-weight: 300!important;
    border-radius: 3px;  
}
