.admin-user-list-page {
  background: black;
  padding-bottom: 70px;
}

.admin-user-list-page h3 {
  font-size: 30px;
  line-height: 1.2;
  color: #fff;
  margin: 0 0 24px;
  font-weight: 500;
}

.admin-user-list-page p {
  font-size: 16px;
  line-height: 26px;
  color: #ffffff;
  margin: 0 0 36px;
}

.img-responsive {
  max-width: 100%;
}
