.admin-user-list-page {
  background: rgba(0, 0, 0, 1);
  padding-bottom: 70px;

  h3{
    font-size:30px;
    line-height: 1.2;
    color:#fff;
    margin: 0 0 24px;
    font-weight: 500;
  }

  p{
    font-size:16px;
    line-height: 26px;
    color:#ffffff;
    margin: 0 0 36px;
  }
 
}


.img-responsive{
  max-width:100%;
}