.sub-container {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    min-height: calc(100vh - 290px);
}

.settings-tab {
    background: #1E1F20;
    padding-top: 22px;
    padding-bottom: 22px;
}

.icon-container {
    display: flex;
    align-items: center;
}

.settings-icon {
    vertical-align: top;
    color: #FFFFFF;
}

.settings-text {
    color: #FFFFFF;
    font-size: 20px!important;
}

.sub-container-body {
    display: flex;
    align-items: center;
    padding-top: 80px;
}

.settings-card {
    padding: 80px 32px;
    background-color: #1E1F20!important;
    min-width: 100%;
    min-height: 200px;
}

.card-row {
    display: flex;
    align-items: center;
}

.card-row p {
    color: #FFFFFF;
}

.token-textfield {
    width: 90%;
    margin-left: 20px!important;
    background: #1E1F20;
}

.token-textfield .MuiOutlinedInput-notchedOutline {
    border-color: #FFFFFF!important;
}

.token-textfield .input-adornment {
    color: #FFFFFF;
}

.token-textfield .input-adornment .form-control {
    min-width: 24px;
    height: 28px;
    background: #707070;
    padding: 2px;
    border-radius: 4px;
    margin-right: -8px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
    cursor: pointer;
}

.token-textfield .input-adornment .form-control .copy-icon {
    font-size: 18px;
}
