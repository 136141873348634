.shareCard-dialog-box .MuiDialog-paper{
  width: 582px;
  max-width: calc(100% - 60px);
  min-height: 528px;
  max-height: calc(100% - 60px);
  border-radius: 6px;
  background: #070110;
  position: relative;
  overflow: initial;
}

.shareCard-dialog-box .MuiDialog-container{
  background: rgba(42,42,42,0.6);
}

.shareCard-dialog-box .MuiDialogTitle-root {
  background: rgba(110, 115, 125, 0.1);
  color: #FFFFFF;
  font-size: 18px;
  font-weight: normal;
  padding: 16px 32px;
}

.shareCard-dialog-box .MuiDialogContent-root {
  border-top: 1px solid rgba(110, 115, 125, 1);
  border-bottom: 1px solid rgba(110, 115, 125, 1);
  padding: 16px 32px;
}

.shareCard-dialog-box .close-dailog{
  position: absolute;
  top:-17px;
  right:-17px;
  background:url('../../assets/icon-close-large.svg') 0 0 no-repeat;
  width:34px;
  height:34px;
  cursor: pointer;
  border:none;
}

.shareCard-dialog-box .share-people-container {
  padding: 8px 0px 24px 0px;
}

.shareCard-dialog-box .share-people .MuiOutlinedInput-notchedOutline {
  border-color: #6E737D;
  border-radius: 100px;
}

.shareCard-dialog-box .share-people .MuiInputBase-root {
  color: #6E737D;
}

.shareCard-dialog-box .MuiAutocomplete-popper .MuiAutocomplete-listbox {
  color: rgba(255, 255, 255, 0.7);
}

.shareCard-dialog-box .sub-heading {
  color: #FFFFFF;
  font-size: 14px;
  margin-bottom: 8px;
}

.shareCard-dialog-box .people-list {
  padding: 0px;
  width: 100%;
}

.shareCard-dialog-box .people-list .MuiListItemAvatar-root {
  min-width: 43px;
}

.shareCard-dialog-box .people-list .MuiAvatar-root {
  width: 32px;
  height: 32px;
}

.shareCard-dialog-box .people-list .MuiListItemText-primary {
  color: rgba(255, 255, 255, 0.7);
  font-size: 14px;
}
.shareCard-dialog-box .people-list .MuiListItemText-secondary {
  color: #6E737D;
  font-size: 12px;
}
.shareCard-dialog-box .people-list .MuiListItemSecondaryAction-root {
  right: 0px;
}

.shareCard-dialog-box .people-list .MuiListItemSecondaryAction-root .MuiButtonBase-root {
  padding: 2px;
  color: #C5C5C5;
  font-size: 12px;
}

.shareCard-dialog-box .people-list .access-dropdown-button {
  color: rgba(255, 255, 255, 0.7);
  padding: 0px;
  text-transform: none;
  font-weight: normal;
  font-size: 14px;
}

.shareCard-dialog-box .people-list .copy-link-button {
  background-color: #C5C5C5;
  border-radius: 100px;
  padding: 7px 14px!important;
  color: #030008!important;
  font-size: 12px!important;
}

.shareCard-dialog-box .general-access-container {
  margin-top: 26px;
}

.shareCard-dialog-box .done-button {
  min-width: 120px;
  background-color: #2674E3;
  border-radius: 3px;
  font-size: 16px;
  font-weight: normal;
}

.shareCard-dialog-box .MuiDialogActions-root {
  padding: 30px;
  justify-content: center;
}
