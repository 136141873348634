.delete-dialog-box .MuiDialog-paper{
  width: 362px;
  max-width: calc(100% - 60px);
  min-height: 314px;
  max-height: calc(100% - 60px);
  border-radius: 6px;
  background: #070110;
  position: relative;
  overflow: initial;
}

.delete-dialog-box .MuiDialog-container{
  background: rgba(42,42,42,0.6);
}

.delete-dialog-box .MuiDialogContent-root {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
}

.delete-dialog-box .delete-icon-container {}

.delete-dialog-box .primary-text {
  margin-top: 12px;
  color: #FFFFFF;
  font-size: 22px;
  text-align: center;
}

.delete-dialog-box .secondary-text {
  margin-top: 12px;
  color: #FFFFFF;
  font-size: 14px;
  text-align: center;
}

.delete-dialog-box .delete-button-container {
  padding-top: 32px;
  padding-bottom: 4px;
  display: flex;
  justify-content: center;
}

.delete-dialog-box .delete-cancel-button {
  min-width: 132px;
  border: 1px solid #474747;
  background-color: #070110;
  border-radius: 3px;
  font-size: 14px;
  font-weight: normal;
  color: #FFFFFF;
  text-transform: none;
  height: 36px;
}

.delete-dialog-box .delete-delete-button {
  min-width: 132px;
  background-color: #2674E3;
  border-radius: 3px;
  font-size: 14px;
  font-weight: normal;
  color: #FFFFFF;
  text-transform: none;
  height: 36px;
  margin-left: 14px;
}
