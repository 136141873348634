.about-page {
  padding-bottom: 70px;
}

.about-page .about-banner {
  margin-bottom: 64px;
  position: relative;
}

.about-page .about-banner img {
  display: block;
  max-width: 100%;
  min-width: 100%;
}

.about-page .about-banner .about-heading {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  font-size: 36px;
  line-height: 1;
  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.about-page h3 {
  font-size: 30px;
  line-height: 1.2;
  color: rgba(255, 255, 255, 0.7);
  margin: 0 0 24px;
  font-weight: 500;
}

.about-page p {
  font-size: 16px;
  line-height: 26px;
  color: rgba(255, 255, 255, 0.7);
  margin: 0 0 36px;
}

.img-responsive {
  max-width: 100%;
}
