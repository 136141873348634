.asset-card-container {
    display: flex;
    width: 645px;
    height: 204px;
    border: 1px solid #312E35;
    border-radius: 8px;
    background: #030007;
}

.asset-card-container:hover .asset-card-play-image {
    display: block;
}

.asset-card-container:hover .asset-card-ellipsis-menu-icon {
    visibility: visible;
}

.asset-card-image-container {
    padding: 11px;
    position: relative;
    min-width: 310px;
    width: 310px;
    cursor: pointer;
}

.asset-card-image-container .asset-card-video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
}

.asset-card-image {
    height: 100%;
}

.asset-card-play-image {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: none;
    cursor: pointer;
}

.asset-card-duration-box {
    position: absolute;
    bottom: 19px;
    right: 19px;
    color: #FFFFFF;
    font-size: 11px;
    background: #0F0E11;
    border-radius: 3px;
    padding: 2px 4px;
}

.asset-card-alert-box {
    position: absolute;
    top: 18px;
    left: 0px;
    background: #119411;
    border-radius: 0px 19px 19px 0px;
    padding: 4px 10px;
    font-size: 12px;
    color: #FFFFFF;
}

.asset-card-details-container {
    padding: 14px 14px 14px 3px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.asset-card-top-section {
    display: flex;
    justify-content: space-between;
}

.asset-card-top-section .rename-field-box {
    display: flex;
    margin-bottom: 6px;
}

.asset-card-top-section .rename-textbox-field {
    min-width: 170px;
    width: 232px;
}

.asset-card-top-section .rename-textbox-field .MuiInputBase-root {
    color: rgba(255, 255, 255, 0.7);
    font-size: 16px;
}

.asset-card-top-section .rename-textbox-field input {
    padding: 4px 8px;
}

.asset-card-top-section .rename-textbox-field .MuiOutlinedInput-notchedOutline {
    border-color: #6E737D;
}

.asset-card-top-section .rename-check-box {
    border-radius: 3px;
    border: 1px solid #6E737D;
    padding: 2px 8px;
    background: #272629;
    cursor: pointer;
    margin-left: 9px;
}

.asset-card-top-section .rename-check-box img {
    width: 13px;
}

.asset-card-top-section .rename-cancel-box {
    border-radius: 3px;
    border: 1px solid #6E737D;
    padding: 2px 9px;
    background: #272629;
    cursor: pointer;
    margin-left: 9px;
}

.asset-card-top-section .rename-cancel-box img {
    width: 11px;
}

.asset-card-details-title {
    font-size: 18px;
    color: rgba(255, 255, 255, 0.7);
}

.asset-card-movie-name {
    font-size: 13px!important;
    color: #C5C5C5;
}

.asset-card-details-description {
    font-size: 13px!important;
    color: #C5C5C5;
    margin-top: 4px!important;
    min-height: 30px;
    max-height: 74px;
    overflow: auto;
    line-height: 1.2!important;
}

.asset-card-description-tooltip .MuiTooltip-tooltip {
    max-width: 500px;
}

.asset-card-date-time {
    font-size: 12px!important;
    color: #6E737D;
}

.asset-card-ellipsis-menu-container {
}

.asset-card-ellipsis-menu-icon {
    visibility: hidden;
}

.asset-card-long-menu {
    border: 1px solid #312E35;
    background-color: #070110!important;
    color: rgba(255, 255, 255, 0.7)!important;
}

.asset-card-long-menu .MuiMenuItem-root {
    font-size: 14px;
    font-weight: 300;
}

.share-user-list {
    padding: 0px!important;
    width: 100%;  
    border-top: 1px solid #312E35;
    margin-top: 12px!important;
}

.share-user-list .MuiListItemAvatar-root {
  min-width: 43px;
}

.share-user-list .MuiAvatar-root {
  width: 32px;
  height: 32px;
}

.share-user-list .MuiListItemText-root {
    margin-bottom: 0px;
}

.share-user-list .MuiListItemText-primary {
  color: rgba(255, 255, 255, 0.7);
  font-size: 14px;
}

.share-user-list .MuiListItemText-secondary {
  color: #6E737D;
  font-size: 12px;
}

