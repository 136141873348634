.contact-page {
  padding-bottom: 70px;
}

.contact-page .contact-banner {
  padding-bottom: 66px;
}

.contact-page .contact-banner img {
  display: block;
  max-width: 100%;
}

.contact-page .contact-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-left: 44px;
  padding-right: 44px;
}

.contact-page .contact-row .contact-info {
  padding: 74px 44px 44px 44px;
  background: #1E1F20;
}

@media (max-width: 899.98px) {
  .contact-page .contact-row .contact-info {
    margin-bottom: 50px;
  }
}

.contact-page .contact-row .contact-info h3 {
  font-size: 23px;
  line-height: 35px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.7);
  margin: 0 0 20px;
}

.contact-page .contact-row .contact-info p {
  font-size: 14px;
  line-height: 1.3;
  font-weight: 500;
  color: #989898;
  margin: 0 0 40px;
}

.contact-page .contact-row .contact-info ul {
  padding: 0px;
  margin: 0px;
}

.contact-page .contact-row .contact-info ul li {
  list-style-type: none;
  color: rgba(255, 255, 255, 0.7);
  padding-left: 36px;
  background: url(../../assets/icon-mail.svg) 0 50% no-repeat;
  font-size: 16px;
  line-height: 27px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 30px;
}

.contact-page .contact-row .contact-info ul li.second {
  background-image: url(../../assets/icon-call.svg);
}

.contact-page .contact-row .contact-info ul li.third {
  background-image: url(../../assets/icon-location.svg);
}

.contact-page .contact-row .contact-form .MuiTextField-root .MuiFormLabel-root {
  position: static;
  color: #989898;
  -webkit-transform: none;
          transform: none;
  font-size: 13px;
}

.contact-page .contact-row .contact-form .MuiTextField-root input {
  border-bottom: solid 1px rgba(255, 255, 255, 0.7);
  padding: 8px 0px;
  color: rgba(255, 255, 255, 0.7);
}

.contact-page .contact-row .contact-form .MuiTextField-root input::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
  opacity: 1;
}

.contact-page .contact-row .contact-form .MuiTextField-root input:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
  opacity: 1;
}

.contact-page .contact-row .contact-form .MuiTextField-root input::-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
  opacity: 1;
}

.contact-page .contact-row .contact-form .MuiTextField-root input::placeholder {
  color: rgba(255, 255, 255, 0.5);
  opacity: 1;
}

.contact-page .contact-row .contact-form .MuiTextField-root fieldset {
  display: none;
}

.contact-page .contact-row .contact-form .btn-submit {
  background: #2674e3;
  font-size: 16px;
  line-height: 19px;
  color: #fff;
  cursor: pointer;
  min-width: 190px;
  padding: 12px;
  border-radius: 3px;
  text-transform: none;
  font-weight: 400;
}
