.badges button {
  border-radius: 50px;
  border: 1px solid #FFFFFF;
  color: #FFFFFF;
  text-transform: capitalize;
  margin-right: 18px;
}

.badges button:hover {
  border: 1px solid #FFFFFF;
}