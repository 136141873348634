.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: transparent;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}
.banner-text{
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left:calc(50% - 442px);
  width: 482px;
  text-align: left;
  letter-spacing: 0.32px;
  color: #D2D2D2;
  opacity: 1;
}
.banner-text button{
  border: 1px solid #FFFFFF;
  border-radius: 3px;
  opacity: 1;
  color: #FFFFFF;
  width: 195px;
  height: 42px;
  margin-top: 24px;
  border-radius: 21px;
}
.banner-text button:hover{
  border: 1px solid #FFFFFF;
}

.banner-text img{
  max-height: 140px;
  object-fit: cover;
  width:auto;
}

@media(max-width:999.98px){
  .banner-text img{
    max-height: 80px;
    width:auto;
  }
} 

.swiper-pagination-bullet {
  background: rgba(255, 255, 255, 1);
}

.swiper-vertical > .swiper-pagination-bullets, .swiper-pagination-vertical.swiper-pagination-bullets {
  right: 83px;
}

.swiper-pagination-bullet-active {
  width: 32px;
  border-radius: 6px;
  background: #FFFFFF;
}

@media (min-width: 1400px) {
  .banner-text{
    left:calc(50% - 393px);
    width: 480px;
  }
}

@media (max-width: 1399.98px) {
  .banner-text{
    left:calc(50% - 354px);
  }
}
@media (max-width: 1299.98px) {
  .banner-text{
    left:calc(50% - 304px);
  }
}
@media (max-width: 1199.98px) {
  .banner-text{
    left:calc(50% - 212px);
  }
}
@media (max-width: 991.98px) {
  .banner-text{
    left:calc(50% - 95px);
  }
}
@media (max-width: 767.98px) {
  .banner-text{
    left:20px;
    width:80%;
    transform: translate(0, -50%);
  }
}
@media (max-width: 899.98px) {
  .banner-text{
    padding-bottom: 0px;
  }
}