@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap");
:root {
  --brand-black:#0C0C0C;
  --brand-black-88:rgba(15,14,17,.88);
  --brand-black-light:#1e1f20;
  --brand-white:#ffffff;
  --brand-white-60:rgba(255,255,255,.6);
  --brand-light-gray:#989898;
  --brand-light-gray-20:rgba(110,115,125, 0.20);
  --brand-light-gray-33:rgba(120,120,120, 0.33);
  --brand-light-gray-1-33:rgba(98,98,98, 0.33);
  --brand-dark-blue:#2674e3;
  --brand-gray-1:#6e737d;
  --brand-gray-2:#D2D2D2;
  --brand-gray-04:rgba(152,152,152,.2);
  --banner-overlay-height:350px;
  --banner-overlay-height-homepage:calc(var(--banner-overlay-height) * 0.50);
  --bg-black-26: rgba(0,0,0,.26);
  --video-box-border: rgba(91, 91, 91, 0.5);
  --theme2-green:#A2E985;
  --theme2-yellow:#FEC868;
  --theme2-cherry:#EC6B62;
}

body * {
  font-family: "Roboto", sans-serif !important;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Roboto", sans-serif !important;
}

body {
  background: #0F0E11 !important;
}

.App {
  text-align: center;
}

#root {
  background-color: #000;
  min-height: 100vh;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: #000;
}

::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.3);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: rgba(255, 255, 255, 0.6);
}

.header {
  left: 50% !important;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

.header .main-header {
  height: auto !important;
  padding-top: 20px;
  padding-bottom: 20px;
}

.header .main-header .MuiToolbar-gutters {
  padding-left: 0px;
  padding-right: 0px;
  min-height: inherit;
}

.header .main-header .MuiToolbar-gutters .MuiIconButton-root {
  margin-left: 0px;
  top: 0px;
  left: 0;
  width: 24px;
  height: 16px;
  border-radius: 0;
  padding: 0;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.header .main-header .MuiToolbar-gutters .MuiIconButton-root .MuiTouchRipple-root {
  height: 2px;
  width: 100%;
  background: white;
  position: static;
}

.header .main-header .MuiToolbar-gutters .MuiIconButton-root .MuiTouchRipple-root:before, .header .main-header .MuiToolbar-gutters .MuiIconButton-root .MuiTouchRipple-root:after {
  display: none;
}

.header .main-header .MuiToolbar-gutters .MuiIconButton-root:before {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 2px;
  background: white;
}

.header .main-header .MuiToolbar-gutters .MuiIconButton-root:after {
  content: "";
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 2px;
  background: white;
}

.header .main-header .MuiFormControl-root {
  margin: 0px;
}

.header .main-header .MuiFormControl-root .MuiOutlinedInput-root {
  padding-right: 0px !important;
}

.header .main-header .MuiFormControl-root .MuiOutlinedInput-root .MuiOutlinedInput-input {
  font-size: 16px;
  font-weight: 300;
}

.header .main-header .MuiFormControl-root .MuiOutlinedInput-root .MuiOutlinedInput-input::-webkit-input-placeholder {
  color: white;
  opacity: 0.40;
}

.header .main-header .MuiFormControl-root .MuiOutlinedInput-root .MuiOutlinedInput-input:-ms-input-placeholder {
  color: white;
  opacity: 0.40;
}

.header .main-header .MuiFormControl-root .MuiOutlinedInput-root .MuiOutlinedInput-input::-ms-input-placeholder {
  color: white;
  opacity: 0.40;
}

.header .main-header .MuiFormControl-root .MuiOutlinedInput-root .MuiOutlinedInput-input::placeholder {
  color: white;
  opacity: 0.40;
}

.header .main-header .MuiChip-outlined {
  margin-right: 0px;
}

.homepage {
  padding-top: 0px;
  margin-top: calc(var(--banner-overlay-height-homepage) * -1);
  position: relative;
  z-index: 1;
}

@media (max-width: 899.98px) {
  .homepage {
    margin-top: 0;
  }
}

.homepage .heading-bar {
  margin: 0 0 30px;
}

.homepage .h3 {
  font-size: 24px;
  line-height: 32px;
  color: white;
  margin: 0 0 0px;
  font-weight: 500;
}

.homepage .view-all {
  font-size: 16px;
  line-height: 19px;
  color: var(--brand-gray-2);
  margin: 0 0 0px;
}

.homepage .carousel-container {
  margin-left: -22px;
  margin-right: -22px;
  width: calc(100% + 44px);
  overflow: hidden;
}

@media (max-width: 1599.98px) {
  .homepage .carousel-container {
    margin-left: -12px;
    margin-right: -12px;
    width: calc(100% + 24px);
  }
}

.homepage .react-multi-carousel-list {
  overflow: visible;
  position: static;
}

.homepage .react-multi-carousel-list ul li {
  padding-left: 22px;
  padding-right: 22px;
}

@media (max-width: 1599.98px) {
  .homepage .react-multi-carousel-list ul li {
    padding-left: 12px;
    padding-right: 12px;
  }
}

.homepage .slider-list {
  position: relative;
}

@media (min-width: 0px) {
  .homepage .slider-list {
    margin-bottom: 20px;
  }
}

@media (min-width: 600px) {
  .homepage .slider-list {
    margin-bottom: 20px;
  }
}

@media (min-width: 900px) {
  .homepage .slider-list {
    margin-bottom: 40px;
  }
}

@media (min-width: 1200px) {
  .homepage .slider-list {
    margin-bottom: 60px;
  }
}

.homepage .react-multiple-carousel__arrow.react-multiple-carousel__arrow--left {
  left: -40px;
  background: transparent;
  min-width: 11px;
  top: calc(50% - 24px);
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.homepage .react-multiple-carousel__arrow.react-multiple-carousel__arrow--left:before {
  content: "";
  background: url(assets/carousel-arrow-icon.svg) 0 0 no-repeat;
  width: 11px;
  height: 20px;
}

.homepage .react-multiple-carousel__arrow.react-multiple-carousel__arrow--right {
  right: -40px;
  background: transparent;
  min-width: 11px;
  top: calc(50% - 24px);
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.homepage .react-multiple-carousel__arrow.react-multiple-carousel__arrow--right:before {
  content: "";
  background: url(assets/carousel-arrow-icon.svg) 0 0 no-repeat;
  width: 11px;
  height: 20px;
  -webkit-transform: rotate(-180deg);
          transform: rotate(-180deg);
}

.search-page-listing {
  overflow: hidden;
}

.search-page-listing:hover .backdrop-up {
  bottom: 0;
}

.search-page-listing .backdrop-down {
  backdrop-filter: blur(17px);
  -webkit-backdrop-filter: blur(17px);
  border-radius: 16px;
  padding: 16px 0px 0px;
}

.search-page-listing .backdrop-up {
  backdrop-filter: blur(17px);
  -webkit-backdrop-filter: blur(17px);
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  padding: 22px 15px 20px;
  width: 100%;
}

.search-page-listing .h3 {
  font-size: 16px;
  line-height: 19px;
  color: white;
  margin: 0 0 11px;
  font-weight: 500;
}

.search-page-listing .h4 {
  font-size: 12px;
  line-height: 13px;
  color: white;
  font-weight: 400;
  margin: 0;
}

.search-page-listing .p {
  font-size: 13px;
  line-height: 16px;
  color: white;
  font-weight: 300;
  margin: 0;
}

.search-page-listing .mb-16 {
  margin-bottom: 16px;
}

.search-page-listing .mb-12 {
  margin-bottom: 12px;
}

.search-page-listing .mb-8 {
  margin-bottom: 8px;
}

.search-page-listing .mb-0 {
  margin-bottom: 0px;
}

.search-page-listing .title-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-column-gap: 10px;
          column-gap: 10px;
}

.search-page-listing .title-group span {
  display: block;
}

.search-page-listing .title-group span + span {
  position: relative;
}

.search-page-listing .title-group span + span:before {
  content: "";
  position: absolute;
  top: 50%;
  left: -5px;
  width: 1px;
  height: 10px;
  background: #ffffff;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.search-page-listing .buttons-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-column-gap: 7px;
          column-gap: 7px;
}

.search-page-listing .buttons-group button {
  background: #fff;
}

.search-page-listing .buttons-group .btn-fav svg {
  fill: #E12B38;
}

.search-page-listing .buttons-group .btn-bug svg {
  fill: #000000;
}

.search-page-listing .buttons-group .btn-play svg {
  fill: #000000;
}

.search-page-listing .badges {
  margin-bottom: 16px;
}

.search-page-listing .badges button {
  border-radius: 50px;
  border: 1px solid #FFFFFF;
  color: #FFFFFF;
  text-transform: capitalize;
  margin-right: 18px;
}

.search-page-listing .badges button:hover {
  border: 1px solid #FFFFFF;
}

.search-page-listing .card-top-row {
  padding: 16px 16px 0 16px;
}

.container {
  width: 100%;
  padding-right: 24px;
  padding-left: 24px;
  margin-right: auto;
  margin-left: auto;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
    padding-right: 12px;
    padding-left: 12px;
  }
}

@media (min-width: 600px) {
  .container {
    max-width: 560px;
    padding-right: 12px;
    padding-left: 12px;
  }
}

@media (min-width: 900px) {
  .container {
    max-width: 860px;
    padding-right: 12px;
    padding-left: 12px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

@media (min-width: 1300px) {
  .container {
    max-width: 1240px;
  }
}

@media (min-width: 1400px) {
  .container {
    max-width: 1320px;
  }
}

.footer {
  background: #0A0710;
  overflow: hidden;
}

.footer .footer-top {
  position: relative;
}

@media (min-width: 0px) {
  .footer .footer-top {
    padding-top: 12px;
    padding-bottom: 12px;
  }
}

@media (min-width: 600px) {
  .footer .footer-top {
    padding-top: 12px;
    padding-bottom: 12px;
  }
}

@media (min-width: 900px) {
  .footer .footer-top {
    padding-top: 12px;
    padding-bottom: 12px;
  }
}

@media (min-width: 1200px) {
  .footer .footer-top {
    padding-top: 18px;
    padding-bottom: 18px;
  }
}

.footer .footer-top:after {
  content: "";
  position: absolute;
  bottom: 0px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  width: 1000%;
  height: 1px;
  background: rgba(152, 152, 152, 0.39);
}

.footer .footer-bottom {
  padding-top: 20px;
  padding-bottom: 20px;
}

.filter-bar {
  display: none !important;
}

.search-tab {
  background: #0A0710;
}

@media (max-width: 899.98px) {
  .search-tab {
    padding-bottom: 12px;
  }
}

.search-tab button {
  font-size: 16px;
  line-height: 19px;
  padding-top: 27px;
  padding-bottom: 27px;
  padding-left: 0px;
  padding-right: 0px;
  color: rgba(110, 115, 125, 0.7);
  text-transform: none;
}

@media (max-width: 899.98px) {
  .search-tab button {
    padding-top: 12px;
    padding-bottom: 12px;
  }
}

.search-tab button + button {
  margin-left: 32px;
}

@media (max-width: 899.98px) {
  .search-tab button + button {
    margin-left: 16px;
  }
}

.search-tab button em {
  font-style: normal;
  font-weight: 400;
}

.search-tab button.Mui-selected {
  color: rgba(255, 255, 255, 0.7);
  font-weight: 400;
}

.search-tab .MuiTabs-indicator {
  height: 3px;
  background-color: rgba(255, 255, 255, 0.7);
}

.playlist-tab {
  padding-top: 22px;
  padding-bottom: 22px;
  background: #030008;
}

.playlist-tab button {
  font-size: 14px;
  line-height: 17px;
  border-radius: 50px;
  border: solid 1px rgba(255, 255, 255, 0.7);
  padding: 6px 12px;
  color: rgba(255, 255, 255, 0.7);
  background: transparent;
  text-transform: none;
  font-weight: 300;
}

.playlist-tab button:hover, .playlist-tab button.active, .playlist-tab button:active {
  background: rgba(255, 255, 255, 0.7);
  color: #000000;
  font-weight: 400;
  border-color: transparent;
}

.playlist-tab button + button {
  margin-left: 10px;
}

.playlist-tab button em {
  font-style: normal;
  font-weight: 400;
}

.playlist-tab button.Mui-selected {
  color: #ffffff;
  font-weight: 400;
}

.playlist-tab button .MuiTouchRipple-root {
  display: none;
}

.playlist-tab .MuiTabs-indicator {
  height: 3px;
  background-color: #ffffff;
}

.playlist-tab .playlist-add button {
  border: solid 1px var(--brand-white);
  border-radius: 50px;
  font-size: 14px;
  line-height: 17px;
  padding: 3px 12px 3px 12px;
  color: var(--brand-white);
  margin-left: 21px;
  background: var(--brand-light-gray-1-33);
  font-weight: 300;
}

.playlist-tab .playlist-add button:hover {
  font-weight: 300;
}

.playlist-tabs-1 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}

.playlist-tabs-1 li {
  margin: 0;
  padding: 0px;
  width: auto;
}

.playlist-tabs-1 li + li {
  margin-left: 10px;
}

.playlist-tab-2 {
  border-top: 1px solid #17151B;
  background: #0A0710;
}

@media (max-width: 899.98px) {
  .playlist-tab-2 {
    padding-bottom: 12px;
  }
}

.playlist-tab-2 button {
  font-size: 16px;
  line-height: 19px;
  padding-top: 27px;
  padding-bottom: 27px;
  padding-left: 0px;
  padding-right: 0px;
  color: rgba(110, 115, 125, 0.7);
  text-transform: none;
}

@media (max-width: 899.98px) {
  .playlist-tab-2 button {
    padding-top: 12px;
    padding-bottom: 12px;
  }
}

.playlist-tab-2 button + button {
  margin-left: 32px;
}

@media (max-width: 899.98px) {
  .playlist-tab-2 button + button {
    margin-left: 16px;
  }
}

.playlist-tab-2 button em {
  font-style: normal;
  font-weight: 400;
}

.playlist-tab-2 button.Mui-selected {
  color: rgba(255, 255, 255, 0.7);
  font-weight: 400;
}

.playlist-tab-2 .MuiTabs-indicator {
  height: 3px;
  background-color: #ffffff;
}

.no-data-message {
  margin: 140px;
  color: #ffffff;
  font-style: normal;
  font-weight: 400;
  text-align: center;
}

.left-menu-drawer .MuiPaper-elevation {
  padding-top: 75px;
  z-index: 1202;
}

.left-menu-drawer .MuiPaper-elevation ul li {
  border-top: solid 1px #474747;
  border-bottom: solid 1px #474747;
  color: #ffffff;
  font-size: 16px;
}

.left-menu-drawer .MuiPaper-elevation ul li + li {
  border-top: none;
  border-bottom: solid 1px #474747;
}

.left-menu-drawer .MuiPaper-elevation ul li a {
  padding-top: 18px;
  padding-bottom: 18px;
  font-size: 16px;
  color: rgba(255, 255, 255, 0.7);
  padding-left: 60px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-column-gap: 13px;
          column-gap: 13px;
}

.left-menu-drawer .MuiPaper-elevation ul li a:hover {
  background: #000000;
}

.left-menu-drawer .MuiPaper-elevation ul li a svg {
  color: rgba(255, 255, 255, 0.7);
}

.filter-menu {
  overflow: hidden;
  position: relative;
}

.filter-menu .filter-inner-bg {
  padding-left: 32px;
  padding-right: 32px;
  position: relative;
  padding-bottom: 100px;
  height: 100vh;
  overflow-y: auto;
}

.filter-menu .MuiPaper-elevation.MuiDrawer-paperAnchorRight {
  background: var(--brand-black);
  z-index: 1299;
}

.filter-menu .MuiPaper-elevation.MuiDrawer-paperAnchorRight .filter-header {
  border-bottom: solid 1px rgba(255, 255, 255, 0.26);
  padding-left: 0px;
  padding-right: 0px;
  margin: 0;
}

.filter-menu .MuiPaper-elevation.MuiDrawer-paperAnchorRight .filter-header .icon-close {
  fill: var(--brand-light-gray);
  cursor: pointer;
}

.filter-menu .MuiPaper-elevation.MuiDrawer-paperAnchorRight .filter-header .filter-title {
  color: rgba(255, 255, 255, 0.7);
}

.filter-menu .MuiPaper-elevation.MuiDrawer-paperAnchorRight .title-films-list {
  border-bottom: solid 1px rgba(255, 255, 255, 0.26);
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.36px;
  padding-bottom: 12px;
  color: rgba(255, 255, 255, 0.7);
  margin-top: 12px;
}

.filter-menu .MuiPaper-elevation.MuiDrawer-paperAnchorRight .search-box {
  border: solid 1px #8A8A8A;
  padding: 3px 0px 3px 16px;
  border-radius: 20px;
  width: 100%;
  font-size: 14px;
}

.filter-menu .MuiPaper-elevation.MuiDrawer-paperAnchorRight .search-box .MuiOutlinedInput-root {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.filter-menu .MuiPaper-elevation.MuiDrawer-paperAnchorRight .search-box .Mui-focused input {
  -webkit-box-shadow: none;
          box-shadow: none;
}

.filter-menu .MuiPaper-elevation.MuiDrawer-paperAnchorRight .search-box fieldset {
  display: none;
}

.filter-menu .MuiPaper-elevation.MuiDrawer-paperAnchorRight .search-box input {
  padding: 0;
  border: none;
  font-weight: 400;
}

.filter-menu .MuiPaper-elevation.MuiDrawer-paperAnchorRight .search-box input::-webkit-input-placeholder {
  font-weight: 300;
}

.filter-menu .MuiPaper-elevation.MuiDrawer-paperAnchorRight .search-box input:-ms-input-placeholder {
  font-weight: 300;
}

.filter-menu .MuiPaper-elevation.MuiDrawer-paperAnchorRight .search-box input::-ms-input-placeholder {
  font-weight: 300;
}

.filter-menu .MuiPaper-elevation.MuiDrawer-paperAnchorRight .search-box input::placeholder {
  font-weight: 300;
}

.filter-menu .MuiPaper-elevation.MuiDrawer-paperAnchorRight .search-box input:focus {
  -webkit-box-shadow: none;
          box-shadow: none;
  outline: none;
}

.filter-menu .MuiPaper-elevation.MuiDrawer-paperAnchorRight .search-box .MuiFormControl-root {
  background: transparent;
}

.filter-menu .MuiPaper-elevation .movie-list-group {
  max-height: 180px;
  overflow-y: auto;
}

.filter-menu .MuiPaper-elevation .movie-list-group::-webkit-scrollbar {
  display: none;
}

.filter-menu .MuiPaper-elevation .movie-list-group::-webkit-scrollbar {
  width: 5px;
  height: 8px;
  background-color: #aaa;
  /* or add it to the track */
}

.filter-menu .MuiPaper-elevation .movie-list-group::-webkit-scrollbar-thumb {
  background: #000;
}

.filter-menu .MuiPaper-elevation .movie-list-group .movie-list .movie-icon {
  width: 45px;
  margin-right: 5px;
  position: relative;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 45px;
          flex: 0 0 45px;
}

.filter-menu .MuiPaper-elevation .movie-list-group .movie-list .movie-icon img, .filter-menu .MuiPaper-elevation .movie-list-group .movie-list .movie-icon video {
  max-width: 100%;
}

.filter-menu .MuiPaper-elevation .movie-list-group .movie-list .movie-icon .icon-play-video {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 20px;
  height: 20px;
}

.filter-menu .MuiPaper-elevation .movie-list-group .movie-list.film-list-card {
  border-radius: 8px !important;
  overflow: hidden !important;
  background: var(--brand-black-light);
  margin-bottom: 12px;
}

.filter-menu .MuiPaper-elevation .movie-list-group .card-description {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 15px;
  position: relative;
}

.filter-menu .MuiPaper-elevation .movie-list-group .card-description:hover .checkbox-icon {
  display: block;
}

.filter-menu .MuiPaper-elevation .movie-list-group .card-description .checkbox-icon {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  right: 8px;
  width: 16px;
  height: 16px;
  fill: var(--brand-white);
}

.filter-menu .MuiPaper-elevation .movie-list-group .h3 {
  font-size: 12px;
  line-height: 15px;
  color: var(--brand-white);
  font-weight: 300;
  margin-bottom: 5px;
}

.filter-menu .MuiPaper-elevation .movie-list-group .h4 {
  font-size: 10px;
  line-height: 1;
  color: #7D8487;
  font-weight: 300;
  margin-bottom: 0px;
}

.filter-menu .flim-list-badges li button {
  font-size: 14px;
  font-weight: 300;
  line-height: 17px;
  padding: 4px 8px;
  border-radius: 50px;
  text-transform: capitalize;
}

.filter-menu .flim-list-badges li button + button {
  margin-left: 8px;
}

.filter-menu .filter-button {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  margin: 0;
  background: var(--brand-black);
}

.filter-menu .video-hierarchical {
  padding: 0 !important;
}

.filter-menu .video-hierarchical .count-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0 !important;
}

.filter-menu .video-hierarchical .v-title {
  font-size: 16px;
  color: rgba(255, 255, 255, 0.7);
  line-height: 1;
}

.filter-menu .video-hierarchical .v-text-box {
  height: auto;
  margin: 0;
}

.filter-menu .video-hierarchical .v-text-box .MuiInputBase-input {
  border: solid 1px var(--brand-white);
  height: auto;
  padding: 0;
  font-size: 14px;
  color: var(--brand-white);
  border-radius: 50px;
  padding: 4px 12px;
}

.filter-menu .flim-list-badges {
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.version-box .MuiAccordionSummary-root {
  padding: 0;
  padding-bottom: 12px;
  border-bottom: solid 1px rgba(255, 255, 255, 0.26);
  min-height: inherit !important;
}

.version-box .MuiAccordionSummary-root .MuiAccordionSummary-content {
  margin-top: 0px;
  margin-bottom: 0px;
}

.version-box .MuiAccordionSummary-root .MuiAccordionSummary-content p {
  margin: 0;
}

.version-box .MuiAccordionSummary-root .MuiSvgIcon-root {
  fill: white;
}

.version-box .version-title {
  padding: 0;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.36px;
  color: #ffffff;
  margin-top: 12px;
}

.version-box .MuiAccordionDetails-root {
  padding: 30px 0 0;
}

.version-box .count-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  padding: 15px;
  border-radius: 10px;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 10px 10px !important;
  margin-top: 15px;
}

.left-menu-close-icon {
  color: white;
  position: absolute;
  top: 29px;
  right: 15px;
  fill: white;
  cursor: pointer;
}

.search-bar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

@media (max-width: 999.98px) {
  .search-bar {
    -webkit-box-flex: 1;
        -ms-flex: 1 0 auto;
            flex: 1 0 auto;
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media (max-width: 459.98px) {
  .search-bar {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 calc(100% - 110px);
            flex: 0 0 calc(100% - 110px);
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (max-width: 999.98px) {
  .search-bar > div {
    width: 100%;
  }
}

.search-bar .MuiOutlinedInput-root {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  width: 100%;
}

.search-bar .MuiOutlinedInput-root.Mui-focused {
  -webkit-box-shadow: none;
          box-shadow: none;
}

.search-bar .MuiTextField-root {
  margin: 8px;
  max-width: 100%;
  border-radius: 100px;
  border: 1px solid var(--brand-gray-1);
}

@media (min-width: 768px) {
  .search-bar .MuiTextField-root {
    width: 562px;
  }
}

.search-bar .MuiTextField-root:hover {
  border: 1px solid var(--brand-gray-1);
}

.search-bar .search-btn {
  min-width: 28px;
  height: 28px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  color: white;
}

.search-bar fieldset {
  display: none;
}

.profile-right-icon {
  position: relative;
}

.profile-right-icon .profile-image-btn {
  border: none;
  background: transparent;
  padding: 0;
  display: block;
}

.profile-right-icon .profile-drop-down {
  position: absolute;
  top: calc(100% + 36px);
  right: -24px;
  margin: 0;
  padding: 26px 16px 16px 16px;
  background: var(--brand-black);
  border-radius: 14px;
}

.profile-right-icon .profile-drop-down:before {
  content: "";
  width: 24px;
  height: 30px;
  position: absolute;
  top: -12px;
  right: 29px;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
  background: var(--brand-black);
}

.profile-right-icon .profile-drop-down li {
  list-style-type: none;
}

.profile-right-icon .profile-drop-down li + li {
  margin-top: 30px;
}

.profile-right-icon .profile-drop-down li .profile-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-column-gap: 12px;
          column-gap: 12px;
}

.profile-right-icon .profile-drop-down li .profile-row .profile-image {
  border-radius: 50%;
  width: 38px;
  height: 38px;
}

.profile-right-icon .profile-drop-down li .profile-row .profile-desc h3 {
  font-size: 16px;
  line-height: 19px;
  color: rgba(255, 255, 255, 0.7);
  margin: 0 0 4px;
  font-weight: 500;
}

.profile-right-icon .profile-drop-down li .profile-row .profile-desc h4 {
  font-size: 14px;
  line-height: 17px;
  color: var(--brand-light-gray);
  margin: 0 0 4px;
  font-weight: 400;
}

.profile-right-icon .profile-drop-down li .btn-logout {
  border: none;
  border-radius: 3px;
  background: var(--brand-dark-blue);
  padding: 10px 0;
  font-size: 16px;
  line-height: 19px;
  width: 100%;
  cursor: pointer;
  color: var(--brand-white);
  font-weight: 400;
}

.mui-switch {
  padding: 0px !important;
  width: 53px !important;
  height: 24px !important;
}

.mui-switch .MuiSwitch-switchBase {
  padding: 0px !important;
}

.mui-switch .MuiSwitch-thumb {
  background: var(--brand-light-gray);
  width: 24px;
  height: 24px;
}

.mui-switch .MuiSwitch-track {
  background: var(--brand-light-gray-20) !important;
  height: 20px;
  width: 57px;
  border-radius: 10px;
  margin-left: 0px;
  margin-top: 2px;
  opacity: 1;
}

.mui-switch .MuiSwitch-track.Mui-checked {
  opacity: 1 !important;
}

.mui-switch .MuiSwitch-switchBase.Mui-checked {
  -webkit-transform: translateX(28px) !important;
          transform: translateX(28px) !important;
}

.mui-switch .MuiSwitch-switchBase.Mui-checked .MuiSwitch-thumb {
  background: var(--brand-dark-blue);
}

.video-page {
  margin-top: 8px !important;
}

.video-page .query-text-section {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 18px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: rgba(255, 255, 255, 0.7);
  text-transform: capitalize;
  font-size: 22px;
}

.video-page .video-box-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 12px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: #fff;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

@media (max-width: 1199.98px) {
  .video-page .video-box-header {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    row-gap: 12px;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
  }
}

.video-page .video-box-header .left-section .movie-meta {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.video-page .video-box-header .left-section .movie-meta p {
  text-transform: capitalize;
  font-size: 18px;
  color: rgba(255, 255, 255, 0.7);
}

.video-page .video-box-header .left-section .movie-meta .rating-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-left: 6px;
  padding: 3px 5px 3px 3px;
  border: 1px solid #fff;
  border-radius: 5px;
  height: 26px;
}

.video-page .video-box-header .left-section .movie-meta .rating-box img {
  width: 38px;
  margin-right: 4px;
}

.video-page .video-box-header .left-section .movie-meta .rating-box .rating-box-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
}

.video-page .video-box-header .left-section .movie-meta .rating-box .rating-box-content .rating-value {
  font-size: 17px;
  line-height: 17px;
  color: white;
  font-weight: 400;
}

.video-page .video-box-header .left-section .movie-meta .rating-box .rating-box-content .rating-max-value {
  font-size: 12px;
  line-height: 12px;
  color: white;
  font-weight: 400;
}

.video-page .video-box-header .left-section .result-section {
  text-align: left;
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0px;
  color: #989898;
  opacity: 1;
  margin-top: 5px;
}

.video-page .video-box-header .right-section {
  -ms-flex-item-align: end;
      align-self: flex-end;
}

@media (max-width: 1199.98px) {
  .video-page .video-box-header .right-section {
    -ms-flex-item-align: start;
        align-self: flex-start;
  }
}

.video-page .video-box-header .right-section .play-mode-toggle-group {
  background: #363738;
  height: 30px;
  border-radius: 15px;
  padding: 2px;
}

.video-page .video-box-header .right-section .play-mode-toggle-group button {
  text-transform: capitalize;
  color: #989898;
  padding: 4px 10px;
  font-size: 12px;
  height: 26px;
  border: 1px solid #000;
  font-weight: normal;
  min-width: 100px;
  background: #0f0e11;
}

.video-page .video-box-header .right-section .play-mode-toggle-group button.Mui-selected {
  background-color: #272629;
  color: #fff;
}

.video-page .video-box-header .right-section .play-mode-toggle-group .first {
  border-radius: 13px 0 0 13px;
}

.video-page .video-box-header .right-section .play-mode-toggle-group .last {
  border-radius: 0 13px 13px 0;
}

.video-page .video-box {
  width: 100%;
  padding: 8px 8px 0px 8px;
  border: solid 1px var(--video-box-border);
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.video-page .video-box .video-player {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  overflow: hidden;
  display: block;
}

.video-page .video-box .video-player video {
  min-height: 390px;
  display: block;
}

.video-page .video-player-controls {
  margin-top: -48px;
  margin-left: 0;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.video-page .video-player-controls .play-btn {
  padding: 0;
  margin: 0;
}

.video-page .video-player-controls .video-seeker-bar {
  -webkit-box-flex: 1;
      -ms-flex: 1 0 auto;
          flex: 1 0 auto;
  position: relative;
  height: 25px;
  width: 100%;
  z-index: 2;
}

.video-page .video-player-controls .icon-zoom {
  display: inline-block;
  width: 16px;
  height: 16px;
  border: 2px dashed var(--brand-white);
  margin: 0px;
}

.video-page .video-player-controls .video-slider-seeker {
  color: #555758;
}

.video-page .video-player-controls .video-slider-seeker .MuiSlider-rail {
  border-radius: 3px;
}

.video-page .video-player-controls .video-slider-seeker .MuiSlider-track {
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  background: #fd0101;
}

.video-page .video-options {
  width: 100%;
  height: 48px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-top: 0px;
  border-top: 1px solid #3E3E3E;
  padding-left: 24px;
  padding-right: 24px;
}

.video-page .video-options .video-seeker-type {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-column-gap: 16px;
          column-gap: 16px;
  color: var(--brand-white);
}

.video-page .video-options .video-seeker-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.video-page .video-options .video-seeker-icon {
  display: inline-block;
  width: 16px;
  height: 16px;
  background: #17DC66;
  margin-left: 4px;
  margin-right: 4px;
  border-radius: 10px;
}

.video-page .video-options .video-seeker-icon.high {
  background: #17DC66;
}

.video-page .video-options .video-seeker-icon.medium {
  background: #F88C07;
}

.video-page .video-options .video-seeker-icon.low {
  background: #E6C51F;
}

.video-page .video-player-controls .cotrol-line {
  width: 100%;
  height: 1px;
  background-color: #fff;
  z-index: 1;
  opacity: .21;
}

.video-page .video-player-controls .video-player-controls-upper {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  height: 48px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  z-index: 1;
  background-image: url("../public/images/player-control-bg.png");
}

.video-page .video-player-controls .video-player-controls-upper .play-btn {
  padding: 0;
  margin: 0 0 0 25px;
  width: 29px;
  height: 29px;
}

.video-page .video-player-controls .video-player-controls-upper .volume-box {
  z-index: 1;
  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-left: 20px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-column-gap: 3px;
          column-gap: 3px;
}

.video-page .video-player-controls .video-player-controls-upper .volume-box svg {
  cursor: pointer;
}

.video-page .video-player-controls .video-player-controls-upper .volume-box .volume-progress {
  cursor: pointer;
  accent-color: #fff;
  height: 4px;
  width: 66px;
  margin: 0;
  padding: 0;
}

.video-page .video-player-controls .video-player-controls-upper .controls-time {
  width: -webkit-fill-available;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  color: #fff;
  font-size: 12px;
  margin-left: 10px;
}

.video-page .video-player-controls .video-player-controls-upper .right-controls {
  color: #fff;
  z-index: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  padding-right: 25px;
}

.video-page .video-player-controls .video-player-controls-upper .right-controls .full-screen {
  cursor: pointer;
}

.video-page .video-player-controls .video-player-controls-upper .play-btn svg {
  width: 29px;
  height: 29px;
  font-size: 29px;
  color: #fff;
}

.image-description-box .playlist-container, .video-desciption-box .playlist-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-bottom: 10px;
  -webkit-column-gap: 7px;
          column-gap: 7px;
}

.image-description-box .playlist-container > div, .video-desciption-box .playlist-container > div {
  -webkit-box-flex: 1;
      -ms-flex: 1 0 auto;
          flex: 1 0 auto;
}

.image-description-box .playlist-container .create-playlist, .video-desciption-box .playlist-container .create-playlist {
  background: #272629;
  border-radius: 3px;
  width: 100%;
  height: 46px;
  border: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 98px;
          flex: 0 0 98px;
  cursor: pointer;
}

.image-description-box .playlist-container .create-playlist .icon-plus, .video-desciption-box .playlist-container .create-playlist .icon-plus {
  position: relative;
  margin-bottom: 4px;
  margin-top: 4px;
  height: 12px;
  width: 12px;
}

.image-description-box .playlist-container .create-playlist .icon-plus:before, .video-desciption-box .playlist-container .create-playlist .icon-plus:before, .image-description-box .playlist-container .create-playlist .icon-plus:after, .video-desciption-box .playlist-container .create-playlist .icon-plus:after {
  width: 2px;
  height: 12px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  content: "";
  background: var(--brand-white);
}

.image-description-box .playlist-container .create-playlist .icon-plus:after, .video-desciption-box .playlist-container .create-playlist .icon-plus:after {
  width: 12px;
  height: 2px;
}

.image-description-box .playlist-container .create-playlist .create-text, .video-desciption-box .playlist-container .create-playlist .create-text {
  font-size: 10px;
  color: var(--brand-white);
  line-height: 1.6;
}

.image-description-box .playlist-container .btn-share, .video-desciption-box .playlist-container .btn-share {
  width: 100%;
  height: 46px;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 46px;
          flex: 0 0 46px;
  border: solid 1px var(--video-box-border);
  border-radius: 3px;
  background: transparent;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  cursor: pointer;
}

.image-description-box .playlist-container .btn-share img, .video-desciption-box .playlist-container .btn-share img {
  max-width: 100%;
}

.image-description-box .playlist-container .add-to-playlist .custom-file-upload, .video-desciption-box .playlist-container .add-to-playlist .custom-file-upload {
  padding: 7px 8px 6px 8px;
  cursor: pointer;
  color: var(--brand-white);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background: #0f0e11;
  border: dashed 1px #272629;
  border-radius: 4px;
}

.image-description-box .playlist-container .add-to-playlist .custom-file-upload span, .video-desciption-box .playlist-container .add-to-playlist .custom-file-upload span {
  position: relative;
  padding-left: 25px;
}

.image-description-box .playlist-container .add-to-playlist .custom-file-upload span:before, .video-desciption-box .playlist-container .add-to-playlist .custom-file-upload span:before {
  content: "";
  background: url(./assets/icon-playlist-white.svg) 0 0 no-repeat;
  width: 15px;
  height: 15px;
  position: absolute;
  top: 0;
  left: 0;
}

.image-description-box .playlist-container .add-to-playlist .custom-file-upload span strong, .video-desciption-box .playlist-container .add-to-playlist .custom-file-upload span strong {
  font-size: 14px;
  line-height: 17px;
  font-weight: 300;
  color: var(--brand-white);
  display: block;
  margin-bottom: 2px;
}

.image-description-box .playlist-container .add-to-playlist .custom-file-upload span em, .video-desciption-box .playlist-container .add-to-playlist .custom-file-upload span em {
  font-size: 10px;
  line-height: 12px;
  font-weight: 300;
  color: var(--brand-light-gray);
  display: block;
  font-style: normal;
}

.video-desciption-box {
  border: solid 1px var(--video-box-border);
  border-radius: 8px;
  padding: 14px;
}

.video-desciption-box h3 {
  font-size: 24px;
  line-height: 35px;
  color: var(--brand-white);
  font-weight: 400;
  margin: 0 0 5px;
}

.video-desciption-box h4 {
  font-size: 14px;
  line-height: 17px;
  color: var(--brand-light-gray);
  font-weight: 400;
  margin: 0 0 0px;
}

.video-desciption-box .video-desc-list {
  padding: 0;
  margin: 0;
  counter-reset: item;
  max-height: 598px;
  overflow-y: auto;
  overflow-x: hidden;
  margin-left: -14px;
  margin-right: -14px;
}

.video-desciption-box .video-desc-list::-webkit-scrollbar {
  display: none;
}

.video-desciption-box .video-desc-list::-webkit-scrollbar {
  width: 5px;
  height: 8px;
  background-color: #aaa;
  /* or add it to the track */
}

.video-desciption-box .video-desc-list::-webkit-scrollbar-thumb {
  background: #000;
}

.video-desciption-box .video-desc-list li {
  color: white;
  list-style-type: none;
  position: relative;
  counter-increment: item;
  cursor: pointer;
  padding-left: 27px;
  padding-right: 14px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.video-desciption-box .video-desc-list li.selected, .video-desciption-box .video-desc-list li:hover {
  background: var(--brand-black-light);
}

.video-desciption-box .video-desc-list li.selected .icon-card-close, .video-desciption-box .video-desc-list li:hover .icon-card-close {
  background-image: url(assets/iconCloseCardHover.svg);
}

.video-desciption-box .video-desc-list li:before {
  content: counter(item);
  position: absolute;
  top: 36px;
  left: 6px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  color: var(--brand-white);
  font-size: 14px;
  line-height: 17px;
  min-width: 16px;
  text-align: right;
}

.video-desciption-box .video-desc-list li .small-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-column-gap: 8px;
          column-gap: 8px;
  margin-left: 0px;
}

.video-desciption-box .video-desc-list li .video-icon {
  max-width: 100px;
  -webkit-box-flex: 1;
      -ms-flex: 1 0 auto;
          flex: 1 0 auto;
  position: relative;
}

.video-desciption-box .video-desc-list li .video-icon .video-time {
  position: absolute;
  bottom: 3px;
  right: 3px;
  background: var(--brand-black);
  font-size: 11px;
  line-height: 1;
  border-radius: 3px;
  padding: 2px 4px;
  color: var(--brand-white);
}

.video-desciption-box .video-desc-list li .video-icon:hover .icon-play-video {
  visibility: visible;
  opacity: 1;
  width: 20px;
  height: 20px;
}

.video-desciption-box .video-desc-list li .video-icon:hover .icon-play-video img {
  max-width: 20px;
  max-height: 20px;
}

.video-desciption-box .video-desc-list li .video-icon img {
  max-width: 100%;
  border-radius: 4px;
  height: 64px;
  -o-object-fit: cover;
     object-fit: cover;
  display: block;
  width: 100px;
}

.video-desciption-box .video-desc-list li .video-icon .icon-play-video {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 0px;
  height: 0px;
  -webkit-transition: visibility 0s, opacity 0.5s linear;
  transition: visibility 0s, opacity 0.5s linear;
  visibility: hidden;
  opacity: 0;
}

.video-desciption-box .video-desc-list li .video-icon .icon-play-video img {
  max-width: 0px;
  max-height: 0px;
}

.video-desciption-box .video-desc-list li .video-desc {
  width: 100%;
}

.video-desciption-box .video-desc-list li .video-desc h3 {
  font-size: 13px;
  line-height: 14px;
  letter-spacing: 0.23px;
  margin-bottom: 3px;
  color: rgba(255, 255, 255, 0.7);
  padding-right: 24px;
}

.video-desciption-box .video-desc-list li .video-desc h4 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.14px;
  color: #6e737d;
  margin-bottom: 0;
  font-weight: 400;
}

.video-desciption-box .video-desc-list li .icon-card-close {
  cursor: pointer;
  margin-left: 24px;
  background: url(assets/iconCloseCard.svg) 0 0 no-repeat;
  width: 20px;
  height: 20px;
}

.video-desciption-box .video-desc-list li .icon-full-desc {
  position: absolute;
  top: 15px;
  right: 24px;
  color: var(--brand-white);
}

.video-desciption-box .video-desc-list li .icon-full-desc svg {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  -webkit-transition: all 0.1s ease-in;
  transition: all 0.1s ease-in;
}

.video-desciption-box .video-desc-list li .icon-full-desc.active svg {
  -webkit-transform: translate(-50%, -50%) rotate(-90deg);
          transform: translate(-50%, -50%) rotate(-90deg);
}

.video-desciption-box .video-desc-list li .full-desc {
  font-size: 12px;
  line-height: 16px;
  color: rgba(255, 255, 255, 0.7);
  font-weight: 300;
  display: none;
  margin-bottom: 0px;
  padding-top: 10px;
}

.video-desciption-box .video-desc-list li .video-desc h4 .video-desc-inner-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 100%;
}

.video-desciption-box .video-desc-list li .video-desc h4 .video-desc-inner-content .first-line {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
  margin-bottom: 1px;
}

.video-desciption-box .video-desc-list li .video-desc h4 .video-desc-inner-content .first-line .movie {
  width: 40%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.video-desciption-box .video-desc-list li .video-desc h4 .video-desc-inner-content .first-line .cateogry-name {
  margin-left: 5px;
  padding-left: 5px;
  width: 60%;
  height: 16px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  /* border-left: 1px solid #989898; */
}

.video-desciption-box .video-desc-list li .video-desc h4 .video-desc-inner-content .second-line {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
}

.video-desciption-box .video-desc-list li .video-desc h4 .video-desc-inner-content .time {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  min-width: 92px;
}

.video-desciption-box .video-desc-list li.timeline-right-list {
  margin: 6px 0px;
}

.video-desciption-box .video-desc-list li.timeline-right-list:hover .icon-play-video {
  visibility: visible;
  opacity: 1;
  width: 20px;
  height: 20px;
}

.video-desciption-box .video-desc-list li.timeline-right-list:hover .icon-play-video img {
  max-width: 20px;
  max-height: 20px;
}

.common-dailog h3 {
  margin: 0 0 22px;
  padding: 0 0 18px;
  font-size: 18px;
  font-weight: 400;
  position: relative;
  color: rgba(255, 255, 255, 0.7);
}

.common-dailog h3:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: -16px;
  right: 0;
  height: 1px;
  width: calc(100% + 32px);
  background: var(--brand-light-gray);
}

.common-dailog ul {
  margin: 0 0 10px;
  padding: 0;
}

.common-dailog ul li {
  list-style-type: none;
  margin-bottom: 19px;
  font-size: 14px;
  line-height: 1;
  color: rgba(255, 255, 255, 0.7);
}

.common-dailog ul li .listhead {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-left: 26px;
  font-size: 14px;
}

.common-dailog ul li .listhead .control_indicator {
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.common-dailog .sep {
  position: relative;
}

.common-dailog .sep:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: -16px;
  right: 0;
  height: 1px;
  width: calc(100% + 32px);
  background: var(--brand-light-gray);
}

.common-dailog .btn-blue {
  background: var(--brand-dark-blue);
  border-radius: 21px;
  font-size: 14px;
  line-height: 1.2;
  padding: 12px;
  width: 100%;
  text-transform: none;
  font-weight: 400;
}

.common-dailog .btn-none {
  color: var(--brand-dark-blue);
  position: relative;
  font-size: 16px;
  line-height: 1.2;
  background: transparent;
  border: none;
  margin-bottom: 22px;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0;
}

.common-dailog .btn-none span {
  display: inline-block;
  width: 16px;
  position: relative;
  height: 10px;
  margin-right: 11px;
}

.common-dailog .btn-none span:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 2px;
  height: 16px;
  background: var(--brand-dark-blue);
}

.common-dailog .btn-none span:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 16px;
  height: 2px;
  background: var(--brand-dark-blue);
}

.common-dailog .btn-cancel {
  width: 34px;
  position: absolute;
  height: 34px;
  top: -10px;
  right: -10px;
  background: var(--brand-white);
  cursor: pointer;
  border-radius: 50%;
}

.common-dailog .btn-cancel span {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: rotate(-45deg) translate(-50%, -50%);
          transform: rotate(-45deg) translate(-50%, -50%);
}

.common-dailog .btn-cancel span:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 2px;
  height: 16px;
  background: var(--brand-black-light);
}

.common-dailog .btn-cancel span:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 16px;
  height: 2px;
  background: var(--brand-black-light);
}

.common-dailog .form-control {
  height: 32px;
  border: none;
  border-bottom: solid 1px var(--brand-light-gray);
  background: transparent;
  font-size: 16px;
  color: var(--brand-light-gray);
  margin-bottom: 30px;
  width: 100%;
}

.common-dailog .form-control:focus {
  -webkit-box-shadow: none;
          box-shadow: none;
  outline: none;
}

.common-dailog .form-control::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.19);
}

.common-dailog .form-control:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.19);
}

.common-dailog .form-control::-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.19);
}

.common-dailog .form-control::placeholder {
  color: rgba(255, 255, 255, 0.19);
}

.common-dailog .inner-dailog {
  min-height: 150px;
}

.listhead {
  display: block;
  position: relative;
  padding-left: 30px;
  margin: 0 0 15px 0;
  cursor: pointer;
  font-size: 18px;
}

.listhead input {
  vertical-align: top;
  margin-right: 10px;
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.listhead .control_indicator {
  position: absolute;
  top: 2px;
  left: 0;
  height: 16px;
  width: 16px;
  background: transparent;
  background: url(assets/playlistUnchekced.svg) 0 0 no-repeat;
}

.listhead input:disabled ~ .control_indicator {
  opacity: 0.5;
  pointer-events: none;
}

.listhead input:checked ~ .control_indicator {
  background-image: url(assets/playlistChekced.svg);
}

.card-item {
  width: 100%;
  background-size: 100% 300px;
  border-radius: 2px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  position: relative;
  overflow: hidden;
}

.card-item .confidence-level {
  position: absolute;
  top: 10px;
  left: 10px;
  font-size: 12px;
  line-height: 1;
  border-radius: 16px;
  padding: 5px 13px;
  color: var(--brand-black);
  z-index: 1;
}

.card-item .confidence-level.alternative {
  position: static;
  display: inline-block;
  margin-bottom: 16px;
}

.card-item .card-time {
  position: absolute;
  bottom: 80px;
  right: 10px;
  background: var(--brand-black);
  font-size: 12px;
  line-height: 1;
  border-radius: 3px;
  padding: 4px 6px;
  color: var(--brand-white);
  z-index: 2;
}

.card-item .card-img-bg {
  width: 100%;
  min-height: 326px;
  margin-bottom: 0px;
  border-radius: 2px;
}

@media (max-width: 1366.98px) {
  .card-item .card-img-bg {
    min-height: 280px;
  }
}

.card-item .card-img-bg:before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent -webkit-gradient(linear, left top, left bottom, from(#00000014), color-stop(55%, #0000001C), to(#000000D6)) 0% 0% no-repeat padding-box;
  background: transparent linear-gradient(180deg, #00000014 0%, #0000001C 55%, #000000D6 100%) 0% 0% no-repeat padding-box;
  content: "";
  z-index: 1;
}

.card-item .card-img-bg:after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  -webkit-box-shadow: inset 0px 0px 22px #0000009E;
          box-shadow: inset 0px 0px 22px #0000009E;
  opacity: .5;
  content: "";
  z-index: 1;
}

.card-item:hover .backdrop-down .h3 {
  font-size: 0;
  color: transparent;
}

.card-item:hover .backdrop-up {
  top: 0;
}

.card-item:hover .backdrop-up .h2 {
  min-height: inherit;
}

.card-item:hover .backdrop-up .h2 .dailog-still-title {
  display: none;
}

.card-item .backdrop-up {
  position: absolute;
  z-index: 2;
  top: calc(100% - 63px);
  height: 100%;
  -webkit-transition: all .5s ease;
  transition: all .5s ease;
  width: 100%;
  height: 0px;
  overflow: hidden;
  height: 100%;
  overflow: visible;
  padding: 10px 16px 12px;
  background: var(--bg-black-26);
  -webkit-backdrop-filter: blur(8px);
          backdrop-filter: blur(8px);
}

.card-item .h2 {
  font-size: 14px;
  line-height: 21px;
  color: rgba(255, 255, 255, 0.85);
  margin: 0 0 8px;
  font-weight: 400;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  min-height: 46px;
}

.card-item .h2 .dailog-still-title {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.card-item .h3 {
  font-size: 14px;
  line-height: 17px;
  color: white;
  margin: 0 0 0px;
  font-weight: 300;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.card-item .h4 {
  font-size: 11px;
  line-height: 15px;
  color: rgba(255, 255, 255, 0.85);
  font-weight: 300;
  margin: 0 0 12px;
}

.card-item .h5 {
  font-size: 13px;
  line-height: 16px;
  color: white;
  font-weight: 300;
  margin: 0 0 16px;
}

.card-item .h5 strong {
  font-weight: 600;
}

.card-item .p {
  font-size: 12px;
  line-height: 16px;
  color: rgba(255, 255, 255, 0.85);
  font-weight: 300;
  margin: 0;
  text-align: justify;
}

.card-item .description-text {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 7;
  -webkit-box-orient: vertical;
}

@media (max-width: 1366.98px) {
  .card-item .description-text {
    -webkit-line-clamp: 5;
  }
}

.card-item .read-more {
  font-weight: 400;
  cursor: pointer;
  text-align: right;
  position: relative;
  padding-right: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  text-decoration: underline;
  text-transform: capitalize;
}

.card-item .mb-16 {
  margin-bottom: 16px;
}

.card-item .mb-12 {
  margin-bottom: 12px;
}

.card-item .mb-8 {
  margin-bottom: 8px;
}

.card-item .mb-0 {
  margin-bottom: 0px;
}

.card-item .title-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-column-gap: 10px;
          column-gap: 10px;
}

.card-item .title-group span {
  display: block;
}

.card-item .title-group span.movie-name {
  text-transform: uppercase;
}

.card-item .title-group span + span {
  position: relative;
}

.card-item .title-group span + span:before {
  content: "";
  position: absolute;
  top: 50%;
  left: -5px;
  width: 1px;
  height: 10px;
  background: #ffffff;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.card-item .badges button {
  border-radius: 50px;
  border: 1px solid rgba(255, 255, 255, 0.85);
  color: rgba(255, 255, 255, 0.85);
  text-transform: capitalize;
  margin-right: 8px;
  font-size: 12px;
  font-weight: 300;
  padding: 1px 11px;
  min-width: inherit;
  margin-bottom: 16px;
}

.card-item .badges button:hover {
  border: 1px solid rgba(255, 255, 255, 0.85);
}

.card-item .card-top-row {
  padding: 16px 16px 0 16px;
  position: relative;
  z-index: 2;
}

.card-item .buttons-group, .buttons-group-svgicons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-column-gap: 18px;
          column-gap: 18px;
}

.card-item .buttons-group button, .buttons-group-svgicons button {
  padding: 0;
}

.card-item .buttons-group button:hover svg path, .buttons-group-svgicons button:hover svg path, .card-item .buttons-group button.active svg path, .buttons-group-svgicons button.active svg path {
  fill: #ffffff;
}

.card-item .buttons-group button svg path, .buttons-group-svgicons button svg path {
  fill: #B3B3B5;
}

.card-item .buttons-group button.btn-stroke, .buttons-group-svgicons button.btn-stroke {
  padding: 0;
}

.card-item .buttons-group button.btn-stroke:hover svg path, .buttons-group-svgicons button.btn-stroke:hover svg path, .card-item .buttons-group button.btn-stroke.active svg path, .buttons-group-svgicons button.btn-stroke.active svg path {
  stroke: #ffffff;
}

.card-item .buttons-group button.btn-stroke svg path, .buttons-group-svgicons button.btn-stroke svg path {
  stroke: #B3B3B5;
}

.card-item .buttons-group button.selected svg path, .buttons-group-svgicons button.selected svg path {
  fill: #e32424;
}

.bug-dailog .MuiDialog-paper {
  background: transparent -webkit-gradient(linear, left top, left bottom, from(#222226), to(#080809)) 0% 0% no-repeat padding-box !important;
  background: transparent linear-gradient(180deg, #222226 0%, #080809 100%) 0% 0% no-repeat padding-box !important;
}

.bug-dailog #customized-dialog-title {
  background: transparent;
  background: transparent -webkit-gradient(linear, left top, right top, from(rgba(152, 152, 152, 0.15)), to(rgba(135, 201, 255, 0.15))) 0% 0% no-repeat padding-box;
  background: transparent linear-gradient(90deg, rgba(152, 152, 152, 0.15) 0%, rgba(135, 201, 255, 0.15) 100%) 0% 0% no-repeat padding-box;
}

.page-min-height {
  min-height: calc(100vh - 402px);
}

.page-min-height-1 {
  min-height: calc(100vh - 144px);
}

.index-page .app-header {
  padding-top: 21px;
  padding-bottom: 21px;
}

@media (max-width: 1199.98px) {
  .index-page .app-header {
    padding-top: 15px;
    padding-bottom: 15px;
  }
}

.index-page .app-header .app-header-inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.index-page .app-header .app-menu ul {
  padding: 0;
  margin: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-column-gap: 32px;
          column-gap: 32px;
}

@media (max-width: 767.98px) {
  .index-page .app-header .app-menu ul {
    -webkit-column-gap: 16px;
            column-gap: 16px;
  }
}

.index-page .app-header .app-menu ul li {
  list-style-type: none;
}

.index-page .app-header .app-menu ul li a {
  font-size: 16px;
  color: #fff;
  line-height: 19px;
  text-decoration: none;
}

.index-page .app-banner {
  min-height: 648px;
  height: calc(100vh - 132px);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  position: relative;
}

@media (max-width: 1199.98px) {
  .index-page .app-banner {
    height: calc(100vh - 120px);
    min-height: 448px;
  }
}

@media (max-width: 767.98px) {
  .index-page .app-banner {
    height: calc(100vh - 129px);
  }
}

.index-page .app-banner img {
  -o-object-fit: cover;
     object-fit: cover;
  display: block;
  position: absolute;
  top: 0;
  left: 0px;
  width: 100%;
  height: 100%;
}

.index-page .app-banner .app-baner-content {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  max-width: 500px;
}

.index-page .app-banner h1 {
  font-family: "oswald", sans-serif !important;
  font-size: 60px;
  font-weight: 700;
  line-height: 1.28;
  margin: 0 0 30px;
  color: var(--brand-white);
}

@media (max-width: 1199.98px) {
  .index-page .app-banner h1 {
    font-size: 48px;
  }
}

@media (max-width: 991.98px) {
  .index-page .app-banner h1 {
    font-size: 32px;
    font-weight: 400;
    margin: 0 0 15px;
  }
}

.index-page .app-banner h4 {
  font-size: 19px;
  font-weight: 600;
  line-height: 1;
  color: rgba(255, 255, 255, 0.5);
  margin: 0 0 9px;
}

@media (max-width: 991.98px) {
  .index-page .app-banner h4 {
    font-size: 14px;
  }
}

.index-page .app-banner h5 {
  font-size: 30px;
  font-weight: 600;
  font-style: italic;
  line-height: 1.56;
  color: white;
  margin: 0 0 54px;
  padding-left: 22px;
  border-left: solid 5px #2674e3;
}

@media (max-width: 991.98px) {
  .index-page .app-banner h5 {
    font-size: 22px;
    margin: 0 0 30px;
  }
}

.index-page .app-banner .btn-contact {
  font-size: 16px;
  font-weight: 600;
  padding: 12px 48px;
  text-transform: uppercase;
  line-height: 1;
  color: white;
  margin: 0 0 9px;
  border: solid 1px var(--brand-white);
  border-radius: 3px;
  background: transparent;
}

.index-page .app-footer {
  background: #0c0c0c;
  font-size: 14px;
  padding-top: 15px;
  padding-bottom: 15px;
  color: var(--brand-white);
}

@media (max-width: 767.98px) {
  .index-page .app-footer {
    padding: 0;
  }
}

.index-page .app-footer .app-footer-inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

@media (max-width: 767.98px) {
  .index-page .app-footer .app-footer-inner {
    height: 60px;
  }
}

.position-relative {
  position: relative;
}

.autocomplete-paper {
  background-color: #0C0C0C  !important;
  padding: 0px !important;
  width: 562px !important;
  margin: 0px !important;
  margin-left: -7px !important;
  border: 1px solid #474747 !important;
  border-top: 0px !important;
  border-radius: 0px 0px 18px 18px  !important;
  font-weight: 300 !important;
}

.search-bar .MuiTextField-root:hover {
  -webkit-box-shadow: none;
          box-shadow: none;
}

.searchInputTextBox {
  padding-left: 6px !important;
  padding-right: 12px !important;
  border-radius: 21px !important;
}

.searchInputTextBox .MuiInputAdornment-positionStart {
  min-width: 40px;
  min-height: 40px;
  margin: -16px 8px -16px -12px;
  border-top-left-radius: 22px;
  border-bottom-left-radius: 22px;
  justify-content: center;
  cursor: pointer;
  position: relative;
}

.searchInputTextBox .MuiInputAdornment-positionStart .searchFilterImageDiv {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-left-radius: 22px;
  border-bottom-left-radius: 22px;
  cursor: pointer;
  border-right: 1px solid #6E737D;
  background: #3A3A3A;
}

.searchInputTextBox .MuiInputAdornment-positionStart .searchInputTextBox-applied {
  border-radius: 19px;
  border: 1px solid #6E737D;
  min-width: 95%;
  height: 95%;
  margin-left: 3px;
}

.searchInputTextBox .MuiInputAdornment-positionStart .searchFilterImageDiv img {
  width: 21px;
}

.searchInputTextBox .MuiInputAdornment-positionStart .filter-highlighter {
  width: 5px;
  min-width: 5px;
  height: 5px;
  background: #FF0000;
  border-radius: 2px;
  position: absolute;
  top: 4px;
  right: 3px;
}

.Mui-expanded .searchInputTextBox {
  border-radius: 21px 21px 0px 0px !important;
  border-bottom: 0px;
}

.Mui-expanded .searchInputTextBox .MuiInputAdornment-positionStart {
  background: none;
}

.MuiAutocomplete-noOptions {
  color: #ffffff !important;
  font-weight: 300;
}

.Mui-focusVisible {
  background-color: #1E1F20 !important;
}

.MuiAutocomplete-option[aria-selected="true"] {
  background-color: inherit !important;
}

.MuiAutocomplete-option[aria-selected="true"].Mui-focused {
  background-color: inherit !important;
}

.empty-container {
  color: white;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 30px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  height: 64vh;
  font-size: 24px;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper.homebanner-swiper .swiper-wrapper .swiper-slide:after {
  background: #080210;
  background: transparent -webkit-gradient(linear, left bottom, left top, from(#070110), color-stop(5%, #070110), color-stop(15%, #070110), to(transparent)) 0% 0% no-repeat padding-box;
  background: transparent linear-gradient(0deg, #070110 0%, #070110 5%, #070110 15%, transparent 100%) 0% 0% no-repeat padding-box;
  opacity: 1;
  position: absolute;
  bottom: 0px;
  height: calc(var(--banner-overlay-height) * 1);
  content: "";
  left: 0px;
  width: 100%;
  z-index: 1;
}

.swiper.homebanner-swiper .swiper-wrapper .swiper-slide:before {
  background: #080210;
  background: transparent linear-gradient(89deg, #000000 0%, #00000085 48%, #00000000 100%) 0% 0% no-repeat padding-box;
  opacity: 1;
  position: absolute;
  bottom: 0px;
  height: 100%;
  content: "";
  left: 0px;
  width: 30%;
  z-index: 1;
}

.swiper .swiper-pagination {
  bottom: calc( var(--banner-overlay-height) * .6) !important;
}

@media (max-width: 899.98px) {
  .swiper .swiper-pagination {
    bottom: 0px !important;
  }
}

.swiper .swiper-pagination-bullet {
  background: var(--brand-white);
  opacity: 0.4;
}

.swiper .swiper-pagination-bullet.swiper-pagination-bullet-active {
  opacity: 1;
  width: 32px;
  border-radius: 4px;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: transparent;
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.banner-text {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  right: 64%;
  width: 482px;
  text-align: left;
  letter-spacing: 0.32px;
  color: #D2D2D2;
  opacity: 1;
  padding-bottom: calc(var(--banner-overlay-height) * .5);
  z-index: 9;
}

.banner-text .banner-desc {
  margin-top: 20px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.banner-text button {
  border: 1px solid #FFFFFF;
  border-radius: 3px;
  opacity: 1;
  color: #FFFFFF;
  width: 195px;
  height: 42px;
  margin-top: 44px;
}

@media (max-width: 767.98px) {
  .banner-text button {
    margin-top: 24px;
  }
}

.banner-text button:hover {
  border: 1px solid #FFFFFF;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

.swiper-pagination-bullet {
  background: white;
}

.swiper-vertical > .swiper-pagination-bullets, .swiper-pagination-vertical.swiper-pagination-bullets {
  right: 83px;
}

.swiper-pagination-bullet-active {
  width: 32px;
  border-radius: 6px;
  background: #FFFFFF;
}

.histogram-bg-parent {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -webkit-column-gap: 3px;
          column-gap: 3px;
}

.histogram-bg-parent .histogram-bg {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 6px;
          flex: 0 0 6px;
  background: #272629;
}

.histogram-bg-parent img {
  height: 50px !important;
  width: 89px !important;
  border-radius: 1px;
}

.cursor-pointer {
  cursor: pointer;
}

.card-dailog-box .video-img-box img, .card-dailog-box .video-img-box video {
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 16px;
}

.card-dailog-box .MuiDialog-paper {
  width: 1208px;
  max-width: calc(100% - 60px);
  height: 523px;
  max-height: calc(100% - 60px);
  border-radius: 22px;
  background: #000000;
  position: relative;
  overflow: initial;
}

.card-dailog-box .MuiDialog-container {
  background: rgba(42, 42, 42, 0.6);
}

.card-dailog-box h4 {
  font-size: 16px;
  line-height: 1.2;
  color: rgba(255, 255, 255, 0.7);
  font-weight: 400;
  margin: 0 0 15px;
}

.card-dailog-box h3 {
  font-size: 24px;
  line-height: 1.458;
  color: rgba(255, 255, 255, 0.7);
  font-weight: 400;
  margin: 0 0 15px;
}

.card-dailog-box ul.year-name {
  padding: 0px;
  margin: 0px 0 14px;
}

.card-dailog-box ul.year-name li {
  list-style-type: none;
  font-size: 16px;
  line-height: 19px;
  color: rgba(255, 255, 255, 0.7);
  font-weight: 300;
  display: inline-block;
}

.card-dailog-box ul.year-name li.movie-name {
  font-weight: 500;
}

.card-dailog-box ul.year-name li + li {
  padding-left: 30px;
  position: relative;
}

.card-dailog-box ul.year-name li + li:before {
  content: "";
  background: var(--brand-white);
  width: 1px;
  height: 12px;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  left: 15px;
}

.card-dailog-box .close-dailog {
  position: absolute;
  top: -17px;
  right: -17px;
  background: url(assets/icon-close-large.svg) 0 0 no-repeat;
  width: 34px;
  height: 34px;
  cursor: pointer;
  border: none;
}

.card-dailog-box .icon-btns-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.card-dailog-box .icon-btns-group .icon-btns {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-column-gap: 18px;
          column-gap: 18px;
}

.card-dailog-box .description {
  margin-top: 24px;
}

.card-dailog-box .description p {
  font-size: 16px;
  color: rgba(255, 255, 255, 0.7);
  margin: 0 0 15px;
}

.filter-condidence-level {
  height: 4px;
}

.filter-condidence-level .MuiSlider-track {
  background: #989898 !important;
  border-radius: 0px !important;
}

.filter-condidence-level .MuiSlider-mark {
  display: none;
}

.MuiAutocomplete-popper .MuiPaper-rounded {
  background: #070110;
  border-color: var(--brand-gray-1) !important;
}

.MuiAutocomplete-popper .MuiAutocomplete-listbox {
  background: #070110;
}

.MuiAutocomplete-popper .MuiAutocomplete-listbox li {
  color: rgba(255, 255, 255, 0.7);
}

.permission-denied-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.permission-denied-container .perm-den-login-button {
  background-color: #2674E3!important;
  font-size: 16px!important;
  text-transform: initial!important;
  font-weight: 300!important;
  border-radius: 3px;
}

.Toastify__toast--success {
  background: #0A170A!important;
  border: 1px solid #1F883D;
}
.Toastify__toast--error {
  background: #190D0D!important;
  border: 1px solid #DF3C30;
}
.Toastify__toast--warning {
  background: #1D1205!important;
  border: 1px solid #D9860E;
}
.Toastify__toast--info {
  background: #042027!important;
  border: 1px solid #0EAFD9;
}
.Toastify__toast {
  font-size: 16px;
}
.Toastify__toast-icon {
  margin-right: 18px;
  width: 25px!important;
}
