.timeline-box {
    flex-direction: column;
    margin-left: 3px;
    width: 100%;
}

.upper-box {
    position: relative;
    background: #0F0E11;
    padding: 3px;
    transition: all 0.2s ease-in;
    border:solid 1px transparent;
}
.histogram-slider .MuiSlider-thumb:before{
    width: 9px !important;
    height: 9px !important;
    border-radius: 50% !important;
    background: rgb(253, 1, 1) !important;
    bottom: 0px !important;
}
.histogram-slider .MuiSlider-thumb:after{
    width: 9px !important;
    height: 9px !important;
    border-radius: 50% !important;
    background: rgb(253, 1, 1) !important;
    top: 0px !important;
}
.histogram-slider{
    padding: 0px !important;
    height: 0px !important;
}
.histogram-slider .MuiSlider-thumb{
    border-radius: 0px !important;
    top: 30px !important;
    width: 1px !important;
    height: 63px !important;
    background: rgb(253, 1, 1) !important;
}
.histogram-slider.smaller-view .MuiSlider-thumb{
    border-radius: 0px !important;
    top: 32px !important;
    width: 1px !important;
    height: 61px !important;
    background: rgb(253, 1, 1) !important;
}

.histogram-slider .MuiSlider-thumb:hover{
    box-shadow: 0px 0px 0px 4px rgba(25, 118, 210, 0.16) !important;
}
.histogram-slider .MuiSlider-thumb:active{
    box-shadow: 0px 0px 0px 4px rgba(25, 118, 210, 0.16) !important;
}
.histogram-slider .MuiSlider-track{
    display: none !important;
}
.timeline-box .duration-box {
  position: absolute;
  bottom: 6px;
  right: 13px;
  background: var(--brand-black-88);
  font-size: 10px;
  line-height: 1;
  border-radius: 3px;
  padding: 2px 4px;
  color: var(--brand-white-60);
}


.timeline-box:hover .upper-box, .timeline-box.active .upper-box {
    background: #0F0E11;
    border-color:#272629;
    transform: scale(1.2, 1.2);
    z-index: 1;
}

.timeline-box:hover .bottomBox-confidence-low {
    color: #EC6B62!important;
}

.timeline-box:hover .bottomBox-confidence-medium {
    color: #FEC868!important;
}

.timeline-box:hover .bottomBox-confidence-high {
    color: #A2E985!important;
}

.timeline-box:hover .bottomBox-noConfidence {
    color: #F88C07!important;
}

.video-desc .confidence-level{
    font-size: 9px;
    line-height: 1;
    padding: 0px 0px 0px 15px;
    min-width: 42px;
    width: 64px;
}

.video-desc .btn-fav{
    padding:0px;
    margin-left: 0px;
}

.video-desc .btn-bug{
    padding:0px;
    margin-left: 13px;
}

.histogram-swiper {
  width: 100%;
  height: 100%;
  position: inherit!important;
  padding: 0 2px !important;
  /* display: flex!important;
  align-items: flex-end; */
}

.swiper-slide {
  background: transparent;
}

.swiper-button-prev {
    left: -26px!important;
    transform: translate(0px, -9px);
}

.swiper-button-prev::after {
    color: transparent;
    background:url(../../assets/icon-arrow-left-01.svg) 0 0 no-repeat;
    font-size: 0px !important;
    content:"";
    width: 9px;
    height: 21px;
    background-size: 100% auto;
}

.swiper-button-next {
    right: -26px!important;
    transform: translate(0px, -9px);
}
.swiper-button-next::after {
    color: transparent;
    background:url(../../assets/icon-arrow-left-01.svg) 0 0 no-repeat;
    font-size: 0px !important;
    content:"";
    width: 9px;
    height: 21px;
    background-size: 100% auto;
    transform: rotate(180deg);
}
.result-list-header-section{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 16px;
}
.result-list-header-section h4{
    margin: 0;
    margin-right: -65px;
}
.confidence-filter-area{
    display: flex;
    height: 17px;
}
.confidence-filter-area .MuiFormGroup-root{
    height: 17px;
    color: #fff;
    flex-direction: row;
}
.confidence-filter-area .MuiFormGroup-root .MuiFormControlLabel-root{
    margin: 0 10px 0 0;
    padding: 0;
    height: 17px;
    display: flex;
    column-gap: 3px;
}
.confidence-filter-area .MuiFormGroup-root .MuiFormControlLabel-root .MuiCheckbox-root{
    padding: 0;
}
.confidence-filter-area .MuiFormGroup-root .MuiTypography-root{
    font-size: 11px;
}
.refresh-icon
{
    cursor: pointer;
}