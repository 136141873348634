.sub-container {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    min-height: calc(100vh - 290px);
}

.generate-video-tab {
    background: #030008;
    padding-top: 22px;
    padding-bottom: 22px;
}

.icon-container {
    display: flex;
    align-items: center;
}

.subheader-text {
    margin-left: 11px!important;
    color: #FFFFFF;
    font-size: 22px!important;
}
